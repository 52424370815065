import { colors } from "../../utils/colors";

export const styles = {
    dropdownBtn: {
        textTransform: 'lowercase',
        padding: '0.1em 0.8em',
        color: colors?.white,
        marginRight: '0.7em',
        background: colors?.themeGreen
    },
    skeleton: {
        fontSize: '1rem',
        minWidth: '120px'
    },
    menuItem: {
        fontSize: '14px',
        padding: '0.5em 1em',
        marginBottom: '0.8em'
    },
    logoutIcon: {
        marginRight: '0.3em',
        fontSize: '20px'
    },
    link: {
        textDecoration: 'none',
        color: colors?.black,
    },
    menuItemIcon: {
        marginRight: '0.3em',
        fontSize: '20px'
    },
    menu: {
        maxHeight: '300px'
    }
}