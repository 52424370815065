// apiService.js
import axios from 'axios';
import config from '../config';
import toast from 'react-hot-toast';

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const get_discoverydata = async () => {
  const url = `${API_BASE_URL}/api/GetInusuranceDiscoverySummaryResponse/1816`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
export const post_registrationdata = async (requestData) => {
  const url = `${API_BASE_URL}/api/UserRegistration/CreateUserRegistration/`
  try {
    const response = await axios.post(url, requestData, {
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const get_registrationUserByUserId = async (userid) => {
  const url = `${API_BASE_URL}/api/UserRegistration/GetUserByUserId?userId=${userid}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};

export const post_discoverysearchdata = async (requestData) => {
  const url = `${API_BASE_URL}/api/InsuranceDiscovery/pVerify/PostInsuranceDiscoveryBySearchParameters`

  try {
    const response = await axios.post(url, requestData, {
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed
      },
    });
    return response.data;
  } catch (error) {
    toast?.error('Error fetching data')
    console.error('Error fetching data:', error);

  }
};

export const get_GetAllInsuranceDiscoveryData = async () => {
  const url = `${API_BASE_URL}/api/InsuranceDiscovery/GetAllInsuranceDiscoveryData`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const get_GetInusuranceDiscoveryByPatientId = async (Patiend_id) => {
  const url = `${API_BASE_URL}/api/InsuranceDiscovery/GetInusuranceDiscoveryByPatientId/${Patiend_id}`;

  try {
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const get_GetPatientInsuranceDetailsById = async (Patiend_id) => {
  const url = `${API_BASE_URL}/api/Patient/GetPatientInsuranceDetails/${Patiend_id}`;

  try {
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response?.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const update_UpdatePatientInsuranceDetails = async (tabName, Patiend_id) => {
  const url = `${API_BASE_URL}/api/Patient/UpdatePatientInsuranceDetails/${tabName}/${Patiend_id}`;

  try {
    const response = await axios.post(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response?.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};


export const AddNpiId = async (requestData) => {
  const url = `${API_BASE_URL}/api/UserRegistration/AddNpiId/${requestData?.userId}/${requestData?.npiId}`
  try {
    const response = await axios.post(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    toast?.error(error?.response?.data?.detail ?? 'NpiId already exist.')
  }
};
