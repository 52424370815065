import { Box, Typography } from '@mui/material'
import React from 'react'
import { colors } from '../../utils/colors'
import HorizontalBox from './HorizontalBox'
import GreenButton from '../claims/GreenButton'

const DetailsHeader = ({ topRightButtons, name, dob }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', justifyContent: 'space-between', bgcolor: '#f4f6f8', p: 2, borderRadius: 2, gap: 2 }}>
            <Typography sx={{ color: colors?.themeGreen, fontWeight: '700', wordSpacing: 2, textTransform: 'uppercase' }}>
                {name ?? ''} {dob ? `(${dob})` : ''}
            </Typography>
            <HorizontalBox sx={{ justifyContent: 'flex-end', gap: 2 }}>
                {topRightButtons?.map(({ name, action }, index) => {
                    return (
                        <GreenButton variant="contained" onClick={action} key={index}>{name}</GreenButton>
                    )
                })}
            </HorizontalBox>
        </Box>
    )
}

export default DetailsHeader