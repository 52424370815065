import React, { useEffect, useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { PatientActionBtn, patientGridOptions, PatientNoResultsgridOptions } from './configuration/PatientTableConfiguration';


const GridComponentPatients = ({ data, filterPatient }) => {
    const [rowData, setRowData] = useState([]);
    const columnDefs = () => {
        return [
            { headerName: 'ID#', field: 'ID', width: 150, filter: true },
            { headerName: 'Appt', field: 'Appt', width: 150, filter: true },
            { headerName: 'Patient', field: 'Patient', filter: true },
            { headerName: 'Phone', field: 'Phone', filter: true },
            { headerName: 'Copay', field: 'Copay', filter: true },
            { headerName: 'Deductible', field: 'Deductible', width: 150 },
            { headerName: 'Insurance', field: 'Insurance', width: 150 },
            { headerName: 'Status', field: 'Status', width: 150 },
            { headerName: '', field: '', cellRenderer: PatientActionBtn, width: 40 },
        ];
    };

    useEffect(() => {
        if (data) {
            // Filter data based on filterPatient
            const filterFirstName = filterPatient?.slice(0, filterPatient?.indexOf(" ")).toLowerCase();
            const filterLastName = filterPatient?.slice(filterPatient?.indexOf(" ")).toLowerCase();
            
            const filteredData = data.filter(patient =>
                patient?.firstName?.toLowerCase()?.includes(filterFirstName) ||
                patient?.lastName?.toLowerCase()?.includes(filterLastName)
            );

            const rowData = filteredData?.length && filteredData?.map(({ firstName, lastName, patientInsuranceDetails, appointments, phone, id }) => ({
                ID: "#" + id || '--------',
                Appt: appointments || '-------',
                Patient: firstName + ' ' + lastName || '-----',
                Phone: phone || '-------',
                Copay: '--------',
                Deductible: '------',
                Insurance: patientInsuranceDetails || '------',
                Status: patientInsuranceDetails || '-------',
            }));
            setRowData(rowData);
        }
    }, [data, filterPatient]);


    return (
        <>
            {
                data && !rowData?.length ? (
                    <div
                        className="ag-theme-quartz"
                        style={{ height: 500 }}>
                        <AgGridReact
                            columnDefs={columnDefs()}
                            rowData={rowData}
                            gridOptions={PatientNoResultsgridOptions}
                        />
                    </div>
                )
                    :
                    <>
                        <div
                            className="ag-theme-quartz"
                            style={{ height: 500 }}>
                            <AgGridReact
                                columnDefs={columnDefs()}
                                rowData={rowData}
                                gridOptions={patientGridOptions}
                            />
                        </div>
                    </>
            }
        </>
    )
};

export default GridComponentPatients;