
import React from 'react'
import ShadowBoxContainer from '../../components/ShadowBoxContainer'
import PatientsOverview from './PatientsOverview'
import PatientsClaimsInsight from './PatientsClaimsInsight'

const Dashboard = () => {
    return (
        <ShadowBoxContainer >
            <PatientsOverview />
            <PatientsClaimsInsight />
        </ShadowBoxContainer>
    )
}

export default Dashboard