import React, { useState, useEffect, useInsertionEffect } from "react";
import SelectTwo from "../../components/select/SelectTwo";
import {
    Box,
    FormControl,
    InputAdornment,
    IconButton,
    Button,
    Grid,
    Autocomplete,
    CircularProgress,
    ClickAwayListener
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Container, Paper, TextField, InputLabel, Select } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import styles from "../../css/addpatient.module.css";
import  "../../css/ClaimSearchStyles.css";
import { useNavigate, Link } from "react-router-dom";
import { post_claimsstatusinquiry } from "../../services/ClaimService";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { get_GetAllPatientsDataAsync, post_Patient } from "../../services/addPatient";
import { post_NpiDataByNpiId, get_NpiDataByNpiNumber, post_Scheduletask } from "../../services/ClaimService";
import FilterListIcon from "@mui/icons-material/FilterList";
import { get_GetAllPayersData, get_GetPayerCodeByName } from "../../services/PayerService";
import dayjs from 'dayjs';
import { getLocalData } from "../../utils/localStorageFunctions";
import { defaultFormDetails, defaultUserDetails, defaultSelectedPatientRequest } from "../../utils/claimDefualtValues"
import { ymdDateFormat, utcFormat } from "../../utils/DateFunctions"
import toast from "react-hot-toast";
import { CenterFocusStrong } from "@mui/icons-material";

const ClaimSearch = () => {
    const [searchInput, setSearchInput] = useState("");
    const navigate = useNavigate();
    const { formWrapper } = styles;
    const [payersData, setPayersData] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [scheduleDate, setScheduleDate] = useState(null);
    const [dateofservice,setDateOfService] = useState(null);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [insurance, setInsurance] = useState([]);
    const [npis, setNpis] = useState([]);
    const [maxDate, setMaxDate] = useState(() => {
        const date = new Date();
        date.setDate(date.getDate() - 14);
        return date;
    });
    //Default npi from user logged in : from browsers storage 
    const userObject = getLocalData('user');
    const defaultNpis = userObject?.doctorNpis ?? '';
    const currentNpi = getLocalData('user')?.doctorNpis[0]?.toString() ?? ''
    const [formDetails, setformDetails] = useState({
        ...defaultFormDetails,
        NPI: currentNpi
    });
    const [isLoading, setIsLoading] = useState(false)
    //Request format
    const [userDetails, setUserDetails] = useState(defaultUserDetails());
    const handleChange1 = (event, value) => {
        if (value !== null) {
            setSearchInput(value);
            fetchData(value);
        }
    };
    const DropDownHandle = (event, newValue) => {
        setInsurance(newValue)
        setformDetails((prevDetails) => ({
            ...prevDetails,
            PatientInsurancePayer: newValue
        }));
    };
    const DropDownNpis = (event, newValue) => {
        if (newValue === null) {
            setNpis(selectedPatient ? selectedPatient?.NPI : defaultNpis)
            newValue = currentNpi
        }
        else {
            setNpis([newValue])
        }
        setformDetails((prevDetails) => ({
            ...prevDetails,
            NPI: newValue,
        }));
    };
    const handleSelectionChange = async (event, newValue) => {
        var selected_patient_request = defaultSelectedPatientRequest();
        if (newValue === null) {
            setSelectedPatient(null);
            setInsurance([])
            setNpis(defaultNpis)
            setDateOfService(null);
            setScheduleDate(null);
            setformDetails({
                ...defaultFormDetails,
                NPI: currentNpi
            });
            return;
        }
        const [namePart, dobPart] = newValue.split('-');
        const [firstName1, lastName1] = namePart.split(' ');
        const dobPart1 = dobPart;
        try {
            let npiInfo = getLocalData('selectedNpi');
            const patientdata = await get_GetAllPatientsDataAsync(npiInfo?.id);
            let specificData = null; // Initialize to null if no match is found
            for (let i = 0; i < patientdata.patientDataList.length; i++) {
                const patient_record = patientdata.patientDataList[i];
                if (firstName1 === patient_record.firstName &&
                    lastName1 === patient_record.lastName
                ) {
                    specificData = patient_record;
                    break;
                }
            }
            selected_patient_request.patientid = specificData.id ? specificData.id : "";
            selected_patient_request.PatientInsurancePayer = specificData.patientInsuranceDetails ? specificData.patientInsuranceDetails : [];
            selected_patient_request.PatientFirstName = specificData.firstName ? specificData.firstName : "";
            selected_patient_request.LastName = specificData.lastName ? specificData.lastName : "";
            selected_patient_request.dateofservice = ymdDateFormat(new Date());
            selected_patient_request.dob = specificData.dob ? specificData.dob : "";
            selected_patient_request.memberId = specificData.memberId ? specificData.memberId : "";
            selected_patient_request.phone = specificData.phone ? specificData.phone : "";
            userDetails.subscriber.dob = dobPart1;
            selected_patient_request.NPI = currentNpi
            setSelectedPatient(selected_patient_request)
            setformDetails(selected_patient_request)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const handleChange = (event, value) => {
        if (event && event.target) {
            const { name, value: targetValue } = event.target;
            if (name === "PatientFirstName") {
                userDetails.subscriber.FirstName = targetValue
                setformDetails((prevDetails) => ({
                    ...prevDetails,
                    PatientFirstName: targetValue,
                }));
            }
            else if (name === "LastName") {
                userDetails.subscriber.lastName = targetValue;
                setformDetails((prevDetails) => ({
                    ...prevDetails,
                    LastName: targetValue,
                }));
            }
            else if (name === "NPI") {
                setformDetails((prevDetails) => ({
                    ...prevDetails,
                    NPI: targetValue,
                }));
            }
            else if (name === "dateofservice") {
                setformDetails((prevDetails) => ({
                    ...prevDetails,
                    dateofservice: ymdDateFormat(dayjs(targetValue)),
                }));
            }
            else {
                setformDetails((prevDetails) => ({
                    ...prevDetails,
                    [name]: targetValue,
                }));
            }
        }
        fetchData(value);
    };

    const handleScheduleTask = async (newValue) => {
        if (newValue) {
                setformDetails((prevDetails) => ({
                    ...prevDetails,
                    scheduleDate: newValue,
                }));
            } 
        }

    const searchbtn = async (e) => {
        e.preventDefault();
        try {
            if (!selectedPatient || selectedPatient?.PatientFirstName !== formDetails.PatientFirstName && selectedPatient?.LastName !== formDetails.LastName) {
                try {
                    let npiInfo = getLocalData('selectedNpi');
                    const patientdata = await get_GetAllPatientsDataAsync(npiInfo?.id);
                    let specificData = null;
                    if (patientdata.patientDataList?.length > 0) {
                        patientdata.patientDataList?.forEach(patient_record => {
                            if (formDetails.PatientFirstName === patient_record?.firstName &&
                                formDetails.LastName === patient_record?.lastName) {
                                specificData = patient_record;
                                return;
                            }
                        });
                    }
                    if (specificData) {
                        formDetails.memberId = specificData?.memberId;
                        formDetails.dob = specificData?.dob;
                    }
                } catch (error) {
                    toast.error('Error fetching data to update the fields');
                }
            }
            setIsLoading(true);
            const npidatabynumber = await get_NpiDataByNpiNumber(formDetails.NPI);
            const payerNameCodePair = {};
            const payerCodes1 = [];
            await Promise.all((formDetails?.PatientInsurancePayer || []).map(async (i) => {
                const payerInfo = await get_GetPayerCodeByName(i);
                if (payerInfo?.[0]) {
                    payerNameCodePair[payerInfo[0].payerName] = payerInfo[0].payerCode;
                    payerCodes1.push(payerInfo[0].payerCode);
                }
            }));
            setformDetails((prevDetails) => ({
                ...prevDetails,
                payerCodes: payerCodes1 ?? [],
            }));
            const updatedDetails = {
                ...userDetails,
                payerCodes: payerCodes1 ?? [],
                ProviderLastName: npidatabynumber?.npiData?.providerLastName ?? '',
                ProviderNPI: formDetails?.NPI?.toString() ?? '',
                serviceStartDate: dayjs(formDetails?.dateofservice).subtract(5, 'day').format('MM/DD/YYYY'),
                serviceEndDate: dayjs(formDetails?.dateofservice).add(5, 'day').format('MM/DD/YYYY'),
                payerCode: payerCodes1[0] ?? '',
                subscriber: {
                    ...userDetails.subscriber,
                    memberID: formDetails?.memberId ?? '',
                    FirstName: formDetails?.PatientFirstName ?? '',
                    lastName: formDetails?.LastName ?? '',
                    dob: formDetails?.dob ?? '',
                },
            };
            setUserDetails(updatedDetails);
            if (formDetails?.PatientFirstName === "" || formDetails?.LastName === "" || formDetails.payerCode === "" || formDetails.dateofservice === "" || formDetails.NPI === "" || formDetails?.scheduleDate == null) {
                toast.error("Enter all the required fields");
            } else {
                const postsearchdata = await post_claimsstatusinquiry(updatedDetails);
                if (formDetails?.scheduleDate === ymdDateFormat(new Date())) {
                    navigate("/Claimresults", { state: { searchData: postsearchdata } });
                } else if (formDetails?.scheduleDate?.isAfter(dayjs())) {
                    let npiInfo = getLocalData('selectedNpi');
                    const scheduleDetails = {
                        UserId: npiInfo?.id,
                        ScheduleType: "claims",
                        ScheduleDate: utcFormat(formDetails?.scheduleDate),
                        ClaimsRequestParameters: {
                            ProviderFederalTaxId: userDetails?.ProviderFederalTaxId,
                            ProviderNPI: formDetails?.NPI?.toString() ?? '',
                            isPatientDependent: userDetails?.isPatientDependent,
                            DateofService: formDetails?.dateofservice
                        }
                    };
                    try {
                        const response = await post_Scheduletask(formDetails?.patientid, scheduleDetails);
                        if (response.isSuccessful) { // Check if the response indicates success
                            toast.success('Task scheduled successfully');
                            setSelectedPatient(null);
                            setInsurance([]);
                            setNpis(defaultNpis);
                            setScheduleDate(null);
                            setDateOfService(null);
                            setformDetails({
                                ...defaultFormDetails,
                                NPI: currentNpi
                            });
                        } else {
                            // Handle the case where the response indicates failure
                            toast.error('Failed to schedule task');
                        }
                    } catch (error) {
                        // Handle any errors that occurred during the request
                        toast.error('An error occurred while scheduling the task');
                        console.error(error);
                    }
                }
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error('Error fetching data');
        }
    };

    const fetchData = async (value) => {
        try {
            let npiInfo = getLocalData('selectedNpi');
            const patientdata = await get_GetAllPatientsDataAsync(npiInfo?.id);
            const flattenedDataObjList = patientdata.patientDataList.map(patient => ({
                firstName: patient.firstName,
                lastName: patient.lastName,
                dob: patient.dob
            }));
            const results = flattenedDataObjList.filter(patient => {
                return (
                    value &&
                    patient.firstName &&
                    patient.lastName &&
                    patient.dob &&
                    (patient.firstName.toLowerCase().includes(value.toLowerCase()) ||
                        patient.lastName.toLowerCase().includes(value.toLowerCase()) ||
                        patient.dob.toLowerCase().includes(value.toLowerCase()))
                );
            });
            setSuggestions(results);
        } catch (error) {
            console.error('Error fetching patient data:', error);
        }
    };
    const PayersDropdown = async () => {
        const payersdata = await get_GetAllPayersData();
        var payers_array = [];
        for (let i = 0; i < payersdata.length; i++) {
            payers_array.push(payersdata[i].payerName)
        }
        setPayersData(payers_array)
    };
    useEffect(() => {
        PayersDropdown();
        setNpis(defaultNpis ?? [])
        if (formDetails && formDetails.PatientInsurancePayer) {
            setInsurance(formDetails.PatientInsurancePayer);
        }
    }, []);

    return (
        <Box>
            <Box className="searchContainer">
                <Box className="searchBox">
                    <FormControl fullWidth>
                        <Autocomplete
                            freeSolo
                            options={suggestions.map((option) => `${option.firstName} ${option.lastName} - ${option.dob}`)}
                            value={searchInput}
                            name="search-drop"
                            onInputChange={handleChange1}
                            onChange={handleSelectionChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Search Patients"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton aria-label="search">
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Box>
            </Box>
            <Box className="formContainer">
                <Box className={formWrapper}>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl className="formControl" fullWidth>
                                    <TextField
                                        label="First Name"
                                        variant="outlined"
                                        name="PatientFirstName"
                                        onChange={handleChange}
                                        color="error"
                                        required
                                        inputProps={{
                                            maxLength: 20,
                                            className: 'capitalizeInput'
                                        }}
                                        value={formDetails ? formDetails.PatientFirstName : ""}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl className="formControl" fullWidth>
                                    <TextField
                                        label="Last Name"
                                        variant="outlined"
                                        name="LastName"
                                        onChange={handleChange}
                                        color="error"
                                        required
                                        inputProps={{
                                            maxLength: 20,
                                            className: 'capitalizeInput'
                                        }}
                                        value={formDetails ? formDetails.LastName : ""}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl className="formControl" fullWidth>
                                    <Autocomplete
                                        multiple
                                        options={payersData}
                                        name="InsurancePayer"
                                        getOptionLabel={(option) => option}
                                        value={formDetails.PatientInsurancePayer ? formDetails.PatientInsurancePayer : null}
                                        onChange={DropDownHandle}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Insurance Name"
                                                variant="outlined"
                                                color="success"
                                                helperText="Select insurance names"
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl className="formControl" fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Date of service"
                                            onChange={(val) => handleChange({ target: { name: 'dateofservice', value: dayjs(new Date(val).toISOString()) } })}
                                            name="dateofservice"
                                            maxDate={dayjs(maxDate)}

                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    color="error"
                                                    required
                                                    inputProps={{ ...params.inputProps, maxLength: 20 }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl className="formControl" fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Schedule Date"
                                            value={
                                                formDetails.scheduleDate
                                                  ? dayjs(formDetails.scheduleDate)
                                                  : dayjs(new Date())
                                              }
                                            onChange={(newValue) => {
                                                setScheduleDate(newValue);
                                                handleScheduleTask(newValue);
                                            }}
                                            disablePast
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl className="formControl" fullWidth>
                                    <TextField
                                        label="NPI"
                                        variant="outlined"
                                        name="NPI"
                                        color="primary"
                                        onChange={handleChange}
                                        value={formDetails?.NPI ?? currentNpi}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} className="textAlignRight">
                                <Button label="Search" variant="contained" color="success" onClick={searchbtn} disabled={isLoading}>
                                    {isLoading ? <CircularProgress size={23} color="inherit" /> : 'Search'}
                                </Button>
                            </Grid>
                            <Grid item xs={6} className="textAlignLeft">
                                <Button label="Cancel" variant="outlined" color="inherit">
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Box>
        </Box>
    )
};
export default ClaimSearch;
