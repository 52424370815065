import React, { useState, useEffect } from 'react'
import SelectTwo from '../../components/select/SelectTwo'
import { useLocation } from 'react-router-dom';
import { Box, FormControl, Input, InputAdornment, IconButton, Button, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TableWrapper from '../../components/table/TableWrapper';
import PaginationWrapper from '../../components/pagination/PaginationWrapper';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FilterListIcon from '@mui/icons-material/FilterList';
import GridComponentClaims from '../../css/GridComponentClaims';
import { Container, Paper, TextField } from '@mui/material';
import styles from '../../css/addpatient.module.css'
import { get_GetAllInsuranceDiscoveryData } from '../../services/registration';
import  "../../css/ClaimSearchStyles.css";
const ClaimSearchresultdata = () => {

    const { formWrapper } = styles;
    const pagename = "ClaimSearchresultdata";
    const location = useLocation();
    const searchData = location.state?.searchData || []; 
    return (
        <Box>
            <Box className="flexBox">
                <Box className="widthBox">
                    <p>{searchData?.patientData?.firstName} {searchData?.patientData?.lastName}</p>
                </Box>
            </Box>
    <Box className="marginTopBox"></Box>
    <GridComponentClaims
        pagename={pagename}
        searchData={searchData}
    />
</Box>
    )
}
export default ClaimSearchresultdata