import { useEffect, useRef, useState } from "react";
import { IconButton, MenuList, MenuItem, Stack } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { Link } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const patientGridOptions = {
    pagination: true,
    paginationPageSize: 10,
    domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
    defaultColDef: {
        flex: 1,
        minWidth: 200
    },
    overlayNoRowsTemplate: `
        <span class="ag-overlay-loading-center">
            Loading
        </span>
    `
};

export const PatientNoResultsgridOptions = {
    pagination: true,
    paginationPageSize: 10,
    domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
    defaultColDef: {
        flex: 1,
        minWidth: 200
    },
    overlayNoRowsTemplate: `
        <span class="ag-overlay-loading-center">
            No Results Found!
        </span>
    `
};

export const PatientActionBtn = ({ data }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef?.current && anchorRef?.current?.contains(event?.target)) {
        return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event?.key === 'Tab') {
        event?.preventDefault();
        setOpen(false);
        } else if (event?.key === 'Escape') {
        setOpen(false);
        }
    }

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen?.current === true && open === false) {
        anchorRef?.current?.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div>
            <IconButton
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? 'composition-menu' : ''}
                aria-expanded={open ? 'true' : 'false'}
                aria-haspopup="true"
                onClick={handleToggle}
                style={{marginBottom: '5px'}}
            >
                <MoreVertIcon fontSize="medium" />
            </IconButton>
            <Popper
                open={open}
                anchorEl={anchorRef?.current}
                role={undefined}
                transition
                placement="bottom-start"
                modifiers={[
                    {
                        name: 'flip',
                        enabled: true,
                        options: {
                            fallbackPlacements: ['top-start', 'bottom-start'],
                        },
                    },
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            altAxis: true,
                            tether: true,
                            rootBoundary: 'document',
                            padding: 8,
                        },
                    },
                ]}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    <MenuItem
                                        onClick={handleClose}
                                        component={Link}
                                        to={`/patients/${data?.ID ? data.ID.replace('#', '') : ''}`}
                                    >
                                        View Details
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

