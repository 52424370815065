export const capitalizeFirtLetter = (value) => {
    return value ? value?.charAt(0)?.toUpperCase() + value?.slice(1) : ''
} 

export const getLastElementFromArray = (data) => {
    return data && data[data?.length - 1];
};


export const formatDateForInput = (date) => {
    return date ? new Date(date)?.toISOString()?.slice(0, 16)?.replace('T', ' ') : '';
};

export const createAppointmentTitle = (appointment) => {
    return `${appointment?.appointmentTitle ?? ''} - ${appointment?.patientName ?? ''}`;
};

export function back() {
    window?.history?.back()
};

export const DownloadCSV = (res, fileName) => {
    const base64ToByteArray = (base64String) => {
        const binaryString = window.atob(base64String);
        const byteArray = new Uint8Array(binaryString?.length);
        for (let i = 0; i < binaryString?.length; i++) {
            byteArray[i] = binaryString?.charCodeAt(i);
        }
        return byteArray;
    };

    const isValidBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };

    if (!isValidBase64(res)) {
        console.log('Invalid base64 string received');
    }

    const byteArray = base64ToByteArray(res);
    const blob = new Blob([byteArray], { type: 'text/csv' });
    const curl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = curl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};



export const base64ToByteArray = (base64String) => {
    const binaryString =  window.atob(base64String);
    const byteArray = new Uint8Array(binaryString?.length);
    for (let i = 0; i < binaryString?.length; i++) {
      byteArray[i] = binaryString?.charCodeAt(i);
    }
    return byteArray;
};
  
export const isValidBase64 = (str) => {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
};

export const trimmedStr = (str, length) => {
    const trimmedVal = str && length ? str?.substring(0, length) : str
    return trimmedVal
}