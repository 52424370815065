import { ymdDateFormat } from "./DateFunctions";

export const defaultFormDetails = {
    payerCodes: [],
    PatientInsurancePayer: [],
    PatientFirstName: "",
    LastName: "",
    dateofservice: "",
    NPI: "",
    memberId: "",
    patientid: "",
    appointment_date: "",
    phone: "",
    dob: "",
    transactionDate: ymdDateFormat(new Date()),
    scheduleDate: ymdDateFormat(new Date())
};

export const defaultUserDetails = () => ({
    transactionDate: ymdDateFormat(new Date()),
    payerCodes: [],
    payerCode: "",
    ProviderLastName: "",
    ProviderNPI: "",
    ProviderFederalTaxId: "",
    serviceStartDate: "",
    serviceEndDate: "",
    subscriber: {
        FirstName: "",
        lastName: "",
        dob: "",
        memberID: "",
        gender: ""
    },
    isPatientDependent: false
});

export const defaultSelectedPatientRequest = () => ({
    payerCodes: [],
    PatientInsurancePayer: [],
    PatientFirstName: "",
    LastName: "",
    dateofservice: "",
    NPI: "",
    memberId: "",
    appointment_date: "",
    phone: "",
    dob: "",
    transactionDate: ymdDateFormat(new Date()),
    scheduleDate: ymdDateFormat(new Date()),
    patientid: ""
});
