
import React, { useState, useEffect } from 'react';
import { get_GetInusuranceDiscoveryByPatientId, get_GetPatientInsuranceDetailsById } from '../../services/registration';
import DetailTab from '../../components/Plandetailtabs/Detailtab';
import CoverageTab from '../../components/Plandetailtabs/coveragetab';
import DemographicInfoTab from '../../components/Plandetailtabs/Demographic';
import Innetwork from '../../components/Plandetailtabs/Innetwork';
import Outofnetwork from '../../components/Plandetailtabs/Outofnetwork';
import Speciality from '../../components/Plandetailtabs/Speciality';
import { Box, CircularProgress } from '@mui/material';
import { sharedStyles } from '../../sharedStyles';

const Plandetail = ({ tabname, rowData }) => {
  const [tabdata, setTabdata] = useState([]);
  const [patientInsuranceTabData, setPatientInsuranceTabData] = useState();
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (rowData && rowData?.ID) {
          let Patient_id = rowData?.ID;
          const data = await get_GetInusuranceDiscoveryByPatientId(Patient_id);
          let planSummaryData = [];
          if (tabname === 'detail') {
            planSummaryData = data?.insuranceDiscoveryDto?.planCoverageSummary;
          } else if (tabname === 'coverage') {
            planSummaryData = data?.insuranceDiscoveryDto?.pcpAuthInfoSummary;
          } else if (tabname === 'Innetwork' || tabname === 'Outofnetwork' || tabname === 'Speciality' || tabname === 'DemographicInfo') {
            planSummaryData = data?.insuranceDiscoveryDto;
          }
          setTabdata([planSummaryData]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [rowData, tabname]);

  useEffect(() => {
    const fetchPatientData = async () => {
      try {
        setIsLoading(true)
        if (rowData && rowData?.ID) {
          let Patient_id = rowData?.ID;
          const data = await get_GetPatientInsuranceDetailsById(Patient_id);
          let planSummaryData = null;
          if (tabname === 'detail') {
            planSummaryData = data?.patientInsuranceData?.planDetails;
          } else if (tabname === 'coverage') {
            planSummaryData = data?.patientInsuranceData?.coverageDetails;
          } else if (tabname === 'Innetwork' || tabname === 'Outofnetwork' || tabname === 'Speciality' || tabname === 'DemographicInfo') {
            planSummaryData = data?.patientInsuranceData;
          }
          // Set the patient insurance data
          setPatientInsuranceTabData(planSummaryData);
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        console.error('Error fetching data:', error);
      }
    };
    fetchPatientData(); // Call fetchPatientData function when component mounts
  }, []);

  const tabComponents = {
    detail: DetailTab,
    coverage: CoverageTab,
    DemographicInfo: DemographicInfoTab,
    Innetwork: Innetwork,
    Outofnetwork: Outofnetwork,
    Speciality: Speciality
  };
  const TabComponent = tabComponents[tabname];

  return (
    <>
      {
        isLoading ?
          <Box sx={sharedStyles?.horizontalCenteredFlex}>
            <CircularProgress color="success" size={22} />
          </Box> :
          <div>
            {tabdata?.length ? tabdata?.map((item, index) => (
              <div key={index}>
                {TabComponent ? <TabComponent data={item} patientInsuranceTabData={patientInsuranceTabData} tabname={tabname} patientId={rowData?.ID} /> : null}
              </div>
            ))
              : ''
            }
          </div>}
    </>
  );
};

export default Plandetail;