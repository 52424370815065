import React from 'react';
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { eventDetailsModalStyles } from './styles';

const EventDetailsModal = ({ open, onClose, event, onEdit, onDelete }) => {
  if (!event) return null;

  const formatDate = (date) => {
    return date ? moment(date).format('MM-DD-YY, h:mm a') : 'N/A';
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={eventDetailsModalStyles.modalBox}>
        <Box sx={eventDetailsModalStyles.headerBox}>
          <Typography sx={eventDetailsModalStyles.modalTitle}>
            {event?.title}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={eventDetailsModalStyles.detailsBox}>
          <Typography>Patient: {event?.patientName}</Typography>
          <Typography>Start: {formatDate(event?.start)}</Typography>
          <Typography>End: {formatDate(event?.end)}</Typography>
          <Typography>Notes: {event?.notes || 'N/A'}</Typography>
        </Box>
        <Box sx={eventDetailsModalStyles.buttonBox}>
          <Button 
            onClick={() => onEdit?.(event)}
            sx={{...eventDetailsModalStyles.button, ...eventDetailsModalStyles.editButton}}
            disableRipple
          >
            Edit
          </Button>
          <Button 
            onClick={() => onDelete?.(event?.id)}
            sx={{...eventDetailsModalStyles.button, ...eventDetailsModalStyles.deleteButton}}
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EventDetailsModal;
