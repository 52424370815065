import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import { get_GetAllInsuranceDiscoveryData } from '../services/registration';
import { post_discoverysearchdata } from '../services/registration';
import React, { useEffect, useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { Box, FormControl, Input, InputAdornment, IconButton, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { get_GetPatientDataById } from '../services/addPatient';
import { get_GetAllClaimsStatusData } from '../services/ClaimService';
import toast from "react-hot-toast";
const GridComponentClaims = ({ pagename, searchData }) => {
    const [rowData, setRowData] = useState([]);
    let navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (pagename === "Claim") {
                    const data = await get_GetAllClaimsStatusData();
                    const flattenedRows1 = [];
                    for (var i = 0; i < data.length; i++) {
                        const flattenedDataObj = {};
                        var patientdetails = await get_GetPatientDataById(data[i].patientId)
                        var patientInfo = patientdetails.patientInfoData
                        flattenedDataObj.ID = patientInfo.id;
                        flattenedDataObj.Patient = patientInfo.firstName + " " + patientInfo.lastName;
                        flattenedDataObj.Phone = patientInfo.phone;
                        flattenedDataObj.DOB = patientInfo.dob;
                        flattenedDataObj.Insurance = patientInfo.patientInsuranceDetails[0];
                        flattenedDataObj.Status = data[i].category;
                        flattenedRows1.push(flattenedDataObj)
                    }
                    setRowData(flattenedRows1)
                }
                else {
                    const data = searchData;
                    const flattenedRows1 = [];
                    const flattenedDataObj = {};
                    try {
                        flattenedDataObj.ID = data.claimStatusRoot.patientId;
                        flattenedDataObj.Patient = data.claimStatusRoot.subscriber.firstName;
                        flattenedDataObj.Phone = data.claimStatusRoot.payerClaimNo;
                        flattenedDataObj.DOB = data.patientData.dob;
                        flattenedDataObj.Insurance = data.claimStatusRoot.providerInfo.name;
                        flattenedDataObj.Status = "";
                        for (var i = 0; i < data.claimStatusRoot.claimStatuses.length; i++) {
                            for (var j = 0; j < data.claimStatusRoot.claimStatuses[i].dates.length; j++) {
                                flattenedDataObj.Date = data.claimStatusRoot.claimStatuses[i].dates[j].value;
                            }
                            for (var k = 0; k < data.claimStatusRoot.claimStatuses[i].statusInfo.statuses.length; k++) {

                                flattenedDataObj.Status = data.claimStatusRoot.claimStatuses[i].statusInfo.statuses[k].category;
                                flattenedDataObj.effectiveDate = data.claimStatusRoot.claimStatuses[i].statusInfo.statuses[k].effectiveDate;
                            }
                        }

                    } catch (error) {
                        toast.error("Error No Response");
                    }

                    flattenedRows1.push(flattenedDataObj)
                    setRowData(flattenedRows1);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const statusCellRenderer = (params) => {
        const statusValue = params.value;
        const statusStyle = {
            backgroundColor: '#F8FFF6',
            color: '#229A16',
        };
        return <span style={statusStyle}>{statusValue}</span>;
    };

    const DetailsButton = ({ data }) => {
        const handleClick = () => {
            navigate({
                pathname: '/Claimdetails1',
                search: `?id=${encodeURIComponent(data?.ID)}`,
            });

        };
        return (
            <Button className='primary' style={{ backgroundColor: 'green', border: 'none', color: 'white', fontSize: 'small' }} onClick={handleClick} >
                View Details
            </Button>
        );
    };
    // Define column definitions based on the pagename prop
    const columnDefs = () => {
        if (pagename === "Claim") {
            return [
                { headerName: 'ID#', field: 'ID', width: 120 },
                { headerName: 'Date', field: 'Date', sortable: true, filter: true },
                { headerName: 'Patient', field: 'Patient', filter: true },
                { headerName: 'Phone', field: 'Phone', filter: true },
                { headerName: 'Date of Birth', field: 'DOB', width: 120 },
                { headerName: 'Insurance Carrier', field: 'Insurance', width: 120, filter: true },
                { headerName: 'Status', field: 'Status', cellRenderer: statusCellRenderer, width: 280, filter: true },
                { headerName: 'Details', field: 'Details', width: 150, filter: true, cellRenderer: DetailsButton, },
            ];
        }
        else {
            return [
                { headerName: 'ID#', field: 'ID', width: 120 },
                { headerName: 'Date', field: 'Date', sortable: true, filter: true },
                { headerName: 'Patient', field: 'Patient', filter: true },
                { headerName: 'Phone', field: 'Phone', filter: true },
                { headerName: 'Date of Birth', field: 'DOB', width: 120 },
                { headerName: 'Insurance Carrier', field: 'Insurance', width: 120, filter: true },
                { headerName: 'Status', field: 'Status', cellRenderer: statusCellRenderer, filter: true },
                { headerName: 'Details', field: 'Details', width: 120, filter: true, cellRenderer: DetailsButton, },
            ];
        }
    };

    const gridOptions = {
        defaultColDef: {
            flex: 1,
            minWidth: 200
        },
        // Enable pagination
        pagination: true,
        // Set the number of rows per page
        paginationPageSize: 10, // Adjust as needed
        // Optional: Specify the layout of pagination components
        domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
        // Other grid options...
    };

    return (
        <div className="ag-theme-quartz"
            style={{ height: 400 }}  >

            <AgGridReact
                columnDefs={columnDefs()}
                rowData={rowData}
                gridOptions={gridOptions}
            />
        </div>
    );
};

export default GridComponentClaims;
