import React from 'react'
import ModalWrapper from '../../components/shared/ModalWrapper/ModalWrapper'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Box, CircularProgress, FormControl } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { styles } from './styles'
import GreenButton from '../../components/claims/GreenButton'
import { sharedStyles } from '../../sharedStyles'

const DateFilterModal = ({open,handleClose,startDate, endDate, setStartDate, setEndDate,action, isLoading}) => {

   
  return (
    <ModalWrapper
       open={open}
       handleClose={handleClose}
       >
        <FormControl sx={styles?.datePicker}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
  label="From"
  value={startDate}
  onChange={(newValue) => setStartDate(newValue)}
  disableFuture={true}
/>
</LocalizationProvider>
        </FormControl>
        <FormControl sx={styles?.datePicker}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
  label="Until"
  value={endDate}
  onChange={(newValue) => setEndDate(newValue)}
  disableFuture={true}
/>
</LocalizationProvider>
        </FormControl>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} sx={sharedStyles?.cursorPointer}>
              <GreenButton variant="contained" onClick={action} disabled={!endDate || !startDate || isLoading}>
                  {isLoading ?
                      <CircularProgress
                          color="inherit" size={22}
                      /> :
                      'Filter'
                  }
              </GreenButton>
      </Box>
       </ModalWrapper>
  )
}

export default DateFilterModal