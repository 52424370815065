import React, { useState, useEffect } from 'react'
import SelectTwo from '../../components/select/SelectTwo'
import { useLocation } from 'react-router-dom';
import { Box, FormControl, Input, InputAdornment, IconButton, Button, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TableWrapper from '../../components/table/TableWrapper';
import PaginationWrapper from '../../components/pagination/PaginationWrapper';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FilterListIcon from '@mui/icons-material/FilterList';
import GridComponent from '../../css/GridComponent';
import { Container, Paper, TextField } from '@mui/material';
import styles from '../../css/addpatient.module.css'
import { get_GetAllInsuranceDiscoveryData } from '../../services/registration';

const Searchresultdata = () => {

    const { formWrapper} = styles;
    const pagename = "Searchresultdata";
    const location = useLocation();
    const searchData = location.state?.searchData || [];

    return (
        <Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1.2em',
          }}>
            <Box sx={{ width: '25%' }}>
            <p>{`${searchData.insuranceDiscoveryDto.patientData.firstName} ${searchData.insuranceDiscoveryDto.patientData.lastName}`}</p>
            </Box>
          </Box>
          <Box sx={{ marginTop: '2em' }}>
          </Box>
          <GridComponent
         pagename={pagename}
         searchData={searchData}
          />
        </Box>
      )
}
export default Searchresultdata