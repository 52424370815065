import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const PageLoader = () => {
    return (
        <Box sx={{
            height: '50vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <CircularProgress />
        </Box>
    )
}

export default PageLoader
