import './App.css';
import './css/Header.css';
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from "react-router-dom";
import '@aws-amplify/ui-react/styles.css';
import Registration from './Pages/Auth/Registration';
import Auth from './Pages/Auth/Auth';
import Layout from './Layout/Layout';
import { isLoggedIn } from './auth/isLoggedIn';
import FindInsurance from './Pages/Insurance/FindInsurance';
import Patients from './Pages/Patients/Patients';
import Discoverytab from './Pages/Discovery/DiscoveryTab';
import Discovery from './Pages/Discovery/Discovery';
import Discoverydetails1 from './Pages/Discovery/Discoverydetails1';
import Search from '@mui/icons-material/Search';
import Searchresults from './Pages/Discovery/Searchresults';
import Searchresultdata from './Pages/Discovery/Searchresultdata';
import Plandetail from './Pages/Discovery/Plandetail';
import Dashboard from './Pages/Dashboard';
import DentalSearchresults from './Pages/Eligibility/DentalSearchresults'
import DentalSearchresultsdata from './Pages/Eligibility/DentalSearchresultdata'
import Claim from './Pages/Claims/Claim';
import Claimdetail from './Pages/Claims/Claimdetail';
import Claimdetails1 from './Pages/Claims/Claimdetails1';
import Claimresults from './Pages/Claims/Claimresults';
import ClaimSearch from './Pages/Claims/ClaimSearch';
import ClaimSearchresultdata from './Pages/Claims/ClaimSearchresultdata';
import ClaimTab from './Pages/Claims/ClaimTab';
import EligibilityTab from './Pages/Eligibility/EligibilityTab'
import { useAuth0 } from '@auth0/auth0-react';
import LandingPage from './Pages/LandingPage';
import { Toaster } from 'react-hot-toast';
import PatientDetail from './Pages/Patients/PatientDetail';
import EditViewProfile from './Pages/Profile/EditViewProfile';
import Profile from './Pages/Profile/Profile';
import NpiDetail from './Pages/Profile/NpiDetail';
import DentalSearch from './Pages/Eligibility/DentalSearch';
import Dentaldetails1 from './Pages/Eligibility/Dentaldetails1';

function App() {
  const { isAuthenticated, loginWithRedirect, getIdTokenClaims, isLoading, logout, } = useAuth0();


  useEffect(() => {
    const checkAuthentication = async () => {
      // Wait for the user to be loaded before checking authentication status
      if (!isLoading) {
        // Wait for a brief delay before redirecting to login
        if (!isAuthenticated) {
          // If not authenticated, redirect to login page
          loginWithRedirect();
        }

      }
    };
    // Call the function to check authentication status
    checkAuthentication();
  }, [isLoading, isAuthenticated, logout, getIdTokenClaims, loginWithRedirect]);

  return (
    <>
      <Router>
        {
          <Routes>
            <Route
              path='/*'
              element={
                isAuthenticated ? (
                  <Layout>
                    <Routes>
                      <Route path='/' element={<LandingPage />} />
                      <Route path="/home" element={<Dashboard />} />
                      <Route path="/Registration" element={<Auth />} />
                      <Route path="/findInsurance" element={<FindInsurance />} />
                      <Route path="/patients" element={<Patients />} />
                      <Route path="/patients/:id" element={<PatientDetail />} />
                      <Route path="/Discoverytab" element={<Discoverytab />} />
                      <Route path="/Discovery" element={<Discovery />} />
                      <Route path="/Search" element={<Search />} />
                      <Route path="/DentalSearch" element={<DentalSearch />} />
                      <Route path="/DentalSearchresults" element={<DentalSearchresults />} />
                      <Route path="/DentalSearchresultsdata" element={<DentalSearchresultsdata />} />
                      <Route path="/Searchresults" element={<Searchresults />} />
                      <Route path="/Searchresultdata" element={<Searchresultdata />} />
                      <Route path="/Discoverydetails1" element={<Discoverydetails1 />} />
                      <Route path="/Plandetail" element={<Plandetail />} />
                      <Route path="/Claim" element={<Claim />} />
                      <Route path="/Claimdetail" element={<Claimdetail />} />
                      {/* <Route path="/Claimdetails1" element={<Claimdetails1 />} /> */}
                      <Route path="/Claimresults" element={<Claimresults />} />
                      <Route path="/ClaimSearch" element={<ClaimSearch />} />
                      <Route path="/ClaimSearchresultdata" element={<ClaimSearchresultdata />} />
                      <Route path="/ClaimTab" element={<ClaimTab />} />
                      <Route path="/Claim" element={<Claim />} />
                      <Route path="/Claimdetail" element={<Claimdetail />} />
                      <Route path="/Claimdetails1" element={<Claimdetails1 />} />
                      <Route path="/Dentaldetails1" element={<Dentaldetails1 />} />
                      <Route path="/Claimresults" element={<Claimresults />} />
                      <Route path="/ClaimSearch" element={<ClaimSearch />} />
                      <Route path="/ClaimSearchresultdata" element={<ClaimSearchresultdata />} />
                      <Route path="/ClaimTab" element={<ClaimTab />} />
                      <Route path="/eligibility" element={<EligibilityTab />} />
                      <Route path="/userSettings" element={<Profile />} />
                      <Route path="/npiDetail" element={<NpiDetail />} />
                    </Routes>
                    <Toaster />
                  </Layout>
                ) : (
                  null
                )
              }
            />
          </Routes>
        }
      </Router>
    </>
  );
}
export default App;