import { Box } from '@mui/material'
import React from 'react'

const HorizontalBox = ({ children, sx }) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
            {children}
        </Box>
    )
}

export default HorizontalBox