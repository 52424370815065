import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
export const isDateInThePast = (date) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const givenDate = new Date(date);
    const checkDate = new Date(givenDate.getFullYear(), givenDate.getMonth(), givenDate.getDate());
    return checkDate < today;
}
export const today = new Date();
export const mdyFormat = (date) => {
    return date?.replace(/^(\d{4})-(\d{2})-(\d{2})$/, (_, year, day, month) => `${year}-${month}-${day}`)
}
export const ymdDateFormat=(date) =>{
    return dayjs(date).format('YYYY-MM-DD').toString()
}
export const formatDate = (date, formatString = 'YYYY-MM-DD') => {
    return dayjs(date).format(formatString)
};

export const formatDateMDY = (date) => {
    return `${date?.getDate()}-${date?.getMonth() + 1}-${date?.getFullYear()}`;
  };
  
export const formatDateYMD = (date) => {
    return `${date?.getFullYear()}-${date?.getMonth() + 1}-${date?.getDate()}`;
  };
  
export const getDateRange = (timeFrame) => {
      const today = new Date();
      let startDate, endDate;
    
      switch (timeFrame) {
        case 'today':
          startDate = new Date(today);
          endDate = new Date(today);
          break;
    
        case '1 week':
          startDate = new Date(today);
          startDate.setDate(today.getDate() - 7);
          endDate = today;
          break;
    
        case '1 month':
          startDate = new Date(today);
          startDate.setMonth(today.getMonth() - 1);
          endDate = today;
          break;
    
        case '6 months':
          startDate = new Date(today);
          startDate.setMonth(today.getMonth() - 6);
          endDate = today;
          break;
    
        case '1 year':
          startDate = new Date(today);
          startDate.setFullYear(today.getFullYear() - 1);
          
          // Adjusting for leap year
          if (today.getDate() === 29 && today.getMonth() === 1) {
            startDate.setDate(1);
          }
    
          endDate = today;
          break;
    
        default:
          console.log('invalid date format')
      }
    
      return {
        from: formatDateMDY(startDate),
        to: formatDateMDY(endDate),
      };
    };
  
export const dateWithSlashes = (dates) => {
  return  `${dates?.from}/${dates?.to}`
}


export const utcFormat = (date) => {
  return dayjs(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')   
};
export const getCurrMonth = () => {
  return (new Date()?.getMonth())
}

export const getCurrentMonthStr = (num) => {
return months[num]
}

export const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];