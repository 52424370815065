import React, { useEffect, useState } from 'react'
import styles from "../../css/syncpatient.module.css";
import { styles as javaScriptStyles } from './styles';
import { Box, Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { isAlphabetOnly } from '../../utils/regexTestFunctions';
import NpiResultsTable from '../../components/table/NpiResultsTable';
import { npiDefault, unitedStates } from '../../utils/staticData';
import { npiSync } from '../../services/NpiFinderInquiry/npiFinder';
import PageLoader from '../../components/loader/PageLoader';
import { postalCodeFormat } from '../../utils/valueFormatter/Formats';
import { useLocation } from 'react-router-dom';
import { getLocalData, removeLocalData, setLocalData } from '../../utils/localStorageFunctions';
import { sharedStyles } from '../../sharedStyles';



const FindNpiDetails = () => {
    const [npiInfo, setNpiInfo] = useState(npiDefault);
    const [data, setData] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const [results, showResults] = useState(false);
    const [showMultiple, setShowMultiple] = useState(false);
    const { formWrapper } = styles;
    const location = useLocation();
    const { state } = location;

    const handleChange = (e) => {
        if (e.target && e.target.name) {
            const { name, value } = e.target;
            if (name === "providerFirstName" || name === "providerLastName") {
                if (!isAlphabetOnly(value)) {
                    return;
                }
            } else if (name === "postalCode") {
                let formattedValue = postalCodeFormat(value);
                setNpiInfo((prevDetails) => ({
                    ...prevDetails,
                    [name]: formattedValue,
                }));
                return;
            }
            setNpiInfo((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { npi, providerFirstName, providerLastName, postalCode, state, city } = npiInfo;
        let payload = {}
        if (!npi || !providerFirstName || !providerLastName || !postalCode || !state || !city) {
            payload = {};
            setShowMultiple(true)
        } else {
            payload = {
                npi: +npi,
                providerFirstName,
                providerLastName,
                postalCode,
                state,
                city
            }
        }
        setLocalData("payload", payload)
        callApi(payload)
    }

    const callApi = async (payload) => {
        setIsloading(true);
        try {
            const response = await npiSync(payload);
            if (response?.data?.npiDataList?.length > 0) {
                setData(response?.data?.npiDataList);
                showResults(true);
                setNpiInfo({
                    city: "",
                    providerFirstName: "",
                    providerLastName: "",
                    npi: null,
                    postalCode: "",
                    state: ""
                })
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsloading(false);
        }
    }

    const clear = () => {
        showResults(false)
        setShowMultiple(false)
        setNpiInfo({
            city: "",
            providerFirstName: "",
            providerLastName: "",
            npi: null,
            postalCode: "",
            state: ""
        })
        removeLocalData("payload")
    }

    useEffect(() => {
        const localData = getLocalData("payload");
        if (localData) {
            callApi(localData);
        }
    }, [])

    return (
        <div>
            {
                isLoading ?
                    (
                        <PageLoader />
                    )
                    :
                    results ?
                        <>
                            <Box sx={sharedStyles?.bm1em}>
                                <NpiResultsTable data={data} />
                            </Box>
                            <Button sx={javaScriptStyles?.whiteBtn} onClick={clear}
                                variant="contained"
                                disabled={isLoading}
                                size="large">
                                Search Again
                            </Button>
                        </>
                        :
                        <Box className={formWrapper}>
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <FormControl
                                            sx={javaScriptStyles?.formControlStyles}
                                            fullWidth
                                        >
                                            <TextField
                                                value={npiInfo?.providerFirstName}
                                                id="outlined-start-adornment"
                                                sx={javaScriptStyles?.textFieldStyles}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                                onChange={handleChange}
                                                name="providerFirstName"
                                                label="Provider First Name"
                                                variant="outlined"
                                                color="success"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <FormControl sx={javaScriptStyles?.formControlStyles} fullWidth>
                                            <TextField
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                                sx={javaScriptStyles?.textFieldStyles}
                                                id="outlined-start-adornment"
                                                onChange={handleChange}
                                                value={npiInfo?.providerLastName}
                                                name="providerLastName"
                                                label="Provider Last Name"
                                                variant="outlined"
                                                color="success"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <FormControl sx={javaScriptStyles?.formControlStyles} fullWidth>
                                            <TextField
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                                sx={javaScriptStyles?.textFieldStyles}
                                                id="outlined-start-adornment"
                                                onChange={handleChange}
                                                value={npiInfo?.npi}
                                                name="npi"
                                                label="NPI"
                                                variant="outlined"
                                                color="success"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <FormControl sx={javaScriptStyles?.formControlStyles} fullWidth>
                                            <TextField
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                                sx={javaScriptStyles?.textFieldStyles}
                                                inputProps={{
                                                    minLength: 5,
                                                    maxLength: 10
                                                }}
                                                id="outlined-start-adornment"
                                                onChange={handleChange}
                                                value={npiInfo?.postalCode}
                                                name="postalCode"
                                                label="Postal Code"
                                                variant="outlined"
                                                color="success"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <FormControl sx={javaScriptStyles?.formControlStyles} fullWidth>
                                            <TextField
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                                sx={javaScriptStyles?.textFieldStyles}
                                                id="outlined-start-adornment"
                                                onChange={handleChange}
                                                value={npiInfo?.city}
                                                name="city"
                                                label="City"
                                                variant="outlined"
                                                color="success"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="State">State</InputLabel>
                                            <Select
                                                labelId="State"
                                                id="demo-simple-select"
                                                value={npiInfo?.state}
                                                label="State"
                                                name='state'
                                                onChange={handleChange}
                                                sx={javaScriptStyles?.borderRadius}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: javaScriptStyles?.paperPropsStlye
                                                    },
                                                }}
                                            >
                                                {
                                                    unitedStates?.map(({ name, code }) => (
                                                        <MenuItem key={code} value={code}>{name}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                        <Box sx={javaScriptStyles?.btnWrapper}>
                                            <Button disabled={isLoading} sx={javaScriptStyles?.whiteBtn} onClick={clear} variant="contained" size="large">
                                                Clear
                                            </Button>
                                            <Button disabled={isLoading} sx={javaScriptStyles?.searchBtn} type="submit" variant="contained" size="small">
                                                Search
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
            }
        </div>
    )
}

export default FindNpiDetails;
