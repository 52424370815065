import { Box } from '@mui/material'
import React from 'react'

const ResponsiveHorizontalBox = ({ children }) => {
    return (
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'stretch'}
            flexDirection={{ xs: 'column', md: 'row' }}

        >
            {children}
        </Box>
    )
}

export default ResponsiveHorizontalBox