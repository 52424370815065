import React, { useState } from 'react';
import SelectTwo from '../../components/select/SelectTwo';
import { Box, FormControl, Input, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import GridComponent from '../../css/GridComponent';
import TableHeader from '../../components/TableHeader';
import GridComponentDentalEligibility from '../../css/GridComponentDentalEligibility';

const Eligibility = () => {
  const pagename = "Eligibility";

  return (
    <Box>
      <Box>
          <TableHeader />
      </Box>
      <Box sx={{ marginTop: '2em' }}>
        <GridComponentDentalEligibility pagename={pagename} />
      </Box>
    </Box>
  );
};

export default Eligibility;
