import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material';
import PatientDashboardCards from './PatientDashboardCards';
import HeadingPlusFilter from '../../components/HeadingPlusFilter';
import PatientsOverviewChart from '../Dashboard/PatientsOverviewChart';
import HorizontalBox from '../../components/shared/HorizontalBox';
import { sharedStyles } from '../../sharedStyles';
import { styles } from './styles';
import DateFilterModal from '../Dashboard/FilterModal';
import { dataLabels, dataSets } from '../../utils/DataFormatters/PatientsOverviewGraphDataFormatters';
import { getPatientOverviewChartDetails } from '../../services/addPatient';
import { patientsOverviewGraphData } from '../../utils/staticData';
import { ToastBar } from 'react-hot-toast';
import { formatDateYMD } from '../../utils/DateFunctions';
import DelayedWrapper from '../../components/DelayedWrapper';
import { patientTabData } from '../Dashboard/dataFormatters';

const PatientDashboard = () => {
    // states
    const [selectedFilter, setSelectedFilter] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [startDate, setStartDate]= useState(null)
    const [endDate, setEndDate] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [filter, setFilter] = useState(false)
    const [patientsOverviewData, setPatientsOverviewData] = useState({patientsOverviewGraphData})
    const [patientsCardData, setPatientsCardData] = useState([])

    // functions
    const getOverviewData = async() => {
        try{ setIsLoading(true)
         const data = await getPatientOverviewChartDetails(selectedFilter)
         if(data?.newPatientsAdded){
             const labels = dataLabels(data)
             const datasets = dataSets(data, labels)
             setPatientsOverviewData({ labels, datasets })
             setShowModal(false)
             setPatientsCardData(data)
 
         }
        }
        catch(e){
             console.log(e,'error fetching data')
             ToastBar.error('error fetching data')
        }
         finally{
             setIsLoading(false)
         }
     }

     const dateFilterAction = () => {
        setSelectedFilter(`${formatDateYMD(new Date(startDate))}/${formatDateYMD(new Date(endDate))}`)
        setFilter(!filter)
    }
    
    //  use Effects
     useEffect(()=>{
        getOverviewData()
     },[selectedFilter, filter])

    return (
        <>
            <HeadingPlusFilter onClick={()=>setShowModal(true)} heading={'Patients Overview'} />
            <PatientDashboardCards data={patientTabData(patientsCardData)} isLoading={isLoading}/>
            <HorizontalBox sx={sharedStyles?.justifyCenter}>
                <Box sx={styles?.chartParentContainer}>
                    <Box sx={styles?.chartContainer}>
                      <DelayedWrapper>
                      <PatientsOverviewChart isLoading={isLoading} 
                            patientsOverviewData={patientsOverviewData}/>
                      </DelayedWrapper>
                    </Box>
                </Box>
            </HorizontalBox>
            <DateFilterModal
                open={showModal}
                handleClose={() => setShowModal(false)}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                action={dateFilterAction}
                isLoading={isLoading}
            />
         
        </>
    )
}

export default PatientDashboard


