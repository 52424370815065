import React, { useEffect, useState } from 'react'
import PageLoader from '../../components/loader/PageLoader';
import { get_GetAllPayersData } from '../../services/PayerService';
import toast from 'react-hot-toast';
import PayersDetail from '../../components/table/PayersDetail';
import { Autocomplete, Box, FormControl, IconButton, InputAdornment, TextField } from '@mui/material';
import styles from '../../css/cardheader.module.css'
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';

const PayerSearch = () => {
    const [isLoading, setIsloading] = useState(false);
    const [payers, setPayers] = useState([]);
    const [input, setInput] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [filterPayer, setFilterPayer] = useState("");
    const { searchWrapper } = styles;


    const fetchData = async (value) => {
        setIsloading(true);
        try {
            const response = await get_GetAllPayersData();
            if (response) {
                setPayers(response);
                // filter by payer name and payer code
                const obj = response?.map(payer => ({
                    payerName: payer?.payerName,
                    payerCode: payer?.payerCode,
                }))

                const results = obj?.filter(payer => {
                    return (
                        value &&
                        (payer?.payerName?.toLowerCase()?.includes(value.toLowerCase()) ||
                            payer?.payerName?.toLowerCase()?.includes(value.toLowerCase()))
                    );
                });
                setSuggestions(results)
            }
        } catch (error) {
            toast.error(error?.response?.message);
        } finally {
            setIsloading(false)
        }
    };

    const handleChange = (event, value) => {
        setInput(value);
        fetchData(value);
        setFilterPayer(value)
    };


    useEffect(() => {
        fetchData(input);
    }, []);


    return (
        <div>
            <Box className={searchWrapper}>
                <FormControl fullWidth>
                    <Autocomplete
                        freeSolo
                        options={suggestions?.map((option) => `${option?.payerName} - ${option?.payerCode}`)}
                        value={input}
                        onInputChange={handleChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Search Payers"
                                InputProps={{
                                    ...params?.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton aria-label="search">
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    />
                </FormControl>
                <IconButton>
                    <FilterListIcon />
                </IconButton>
            </Box>
            {
                    <>
                        <PayersDetail data={payers} filterPayer={filterPayer} />
                    </>

            }
        </div>
    )
}

export default PayerSearch
