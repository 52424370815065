import React, { useState, useEffect } from "react";
import SelectTwo from "../../components/select/SelectTwo";
import {
  Box,
  FormControl,
  InputAdornment,
  IconButton,
  Button,
  Grid,
  Autocomplete,
  TextField,
  Container,
  Paper
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useNavigate } from "react-router-dom";
import { get_GetAllPatientsDataAsync, post_Patient } from "../../services/addPatient";
import { getEligibilitySummaryResponse } from "../../services/eligibility";
import { post_NpiDataByNpiId } from "../../services/ClaimService";
import { post_discoverysearchdata } from "../../services/registration";
import styles from "../../css/addpatient.module.css";
import dayjs from "dayjs";
import { getLocalData } from "../../utils/localStorageFunctions";

const Search = () => {
  const navigate = useNavigate();
  const { formWrapper } = styles;
  const [suggestions, setSuggestions] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [input, setInput] = useState("");
  const [emailError, setEmailError] = useState("");
  var patientInsurancePayer = null;




  const [userDetails, setUserDetails] = useState({
    patientLastName: "",
    patientFirstName: "",
    patientDOB: "2024-06-05T05:29:57.268Z",
    requestNpi: 0,
    dateOfService: "2024-06-05T05:29:57.268Z",
    phoneNumber: "",
    socialSecurityNumber: "",
    email: "",
    appointmentDate: "",
    insurancename: "",
    memberId: 0,
    groupNumber: 0
  });

  useEffect(() => {
    console.log("userDetails after Select use :", userDetails);
  }, [userDetails]);

  const handleSelectionChange = async (event, newValue) => {

    if (newValue === null) {
      setSelectedItem(null);
      setSelectedPatient(null);
      setUserDetails({
        patientLastName: "",
        patientFirstName: "",
        patientDOB: "2024-06-05T05:29:57.268Z",
        requestNpi: 0,
        dateOfService: "2024-06-05T05:29:57.268Z",
        phoneNumber: "",
        socialSecurityNumber: "",
        email: "",
        appointmentDate: "",
        insurancename: "",
        memberId: 0,
        groupNumber: ""
      });
      return;
    }
    else {
      setSelectedItem(newValue);

      const [namePart, dobPart] = newValue.split("-");
      const [firstName, lastName] = namePart.split(" ");
      const firstName1 = firstName;
      const lastName1 = lastName;
      const dobPart1 = dobPart;


      try {
                      
        let npiInfo = getLocalData('selectedNpi');
        const patientdata = await get_GetAllPatientsDataAsync(npiInfo?.id);
      

        let specificData = null;

        for (let i = 0; i < patientdata.patientDataList.length; i++) {
          const patient_record = patientdata.patientDataList[i];

          if (firstName1 === patient_record.firstName && lastName1 === patient_record.lastName) {
            specificData = patient_record;
            break;
          }
        }

        let patient_user = { groupNumber: 0, requestNpi: 0, patientDOB: "2024-06-05T05:29:57.268Z", dateOfService: "2024-06-05T05:29:57.269Z" };

        if (specificData.firstName) {
          patient_user.patientFirstName = specificData.firstName

        }
        else { patient_user.patientFirstName = "" }

        if (specificData.lastName) { patient_user.patientLastName = specificData.lastName }
        else { patient_user.patientLastName = "" }
        if (specificData.dob) { patient_user.patientDOB = specificData.dob }
        else { patient_user.patientDOB = "" }

        if (specificData.appointments) { patient_user.dateOfService = specificData.appointments[0] }
        else { patient_user.dateOfService = "" }



        if (specificData.email) { patient_user.email = specificData.email }
        else { patient_user.email = "" }

        if (specificData.phone) { patient_user.phoneNumber = specificData.phone }
        else { patient_user.phoneNumber = "" }

        if (specificData.patientInsuranceDetails) {
          if (typeof specificData.patientInsuranceDetails != "undefined") {

            patientInsurancePayer = specificData.patientInsuranceDetails[0];
            patient_user.insurancename = patientInsurancePayer;
          }
        }
        else {
          patient_user.insurancename = ""

        }
        if (specificData.ssn) {
          patient_user.socialSecurityNumber = specificData.ssn

        }
        else { patient_user.socialSecurityNumber = "" }



        if (specificData.memberId) { patient_user.memberId = specificData.memberId }
        else { patient_user.memberId = 0 }


        setSelectedPatient(patient_user)

        setUserDetails(patient_user);
        //useeffect 



      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

  };

  const displaySelectedItem = (item) => {
    if (item) {
      console.log(`Selected Item: ${item}`);
    }
  };

  const handleChange = (event, value) => {
    if (event && event.target) {
      const { name, value: targetValue } = event.target;

      setUserDetails((prevState) => ({
        ...prevState,
        [name]: targetValue,
      }));
      if (name === "FirstName") {

        if (selectedPatient) {
          setSelectedPatient((prevPatient) => ({
            ...prevPatient,
            patientFirstName: targetValue,
          }));
        } else {
          setUserDetails((prevState) => ({
            ...prevState,
            patientFirstName: targetValue,
          }));
        }
        userDetails.patientFirstName = targetValue

      }
      if (name === "LastName") {

        if (selectedPatient) {
          setSelectedPatient((prevPatient) => ({
            ...prevPatient,
            patientLastName: targetValue,
          }));
        } else {
          setUserDetails((prevState) => ({
            ...prevState,
            patientLastName: targetValue,
          }));
        }
        userDetails.patientLastName = targetValue;
      }
      if (name === "insurance") {

        if (selectedPatient) {
          setSelectedPatient((prevPatient) => ({
            ...prevPatient,
            insurancename: targetValue,
          }));
        } else {
          setUserDetails((prevState) => ({
            ...prevState,
            insurancename: targetValue,
          }));
        }
        userDetails.insurancename = targetValue;

      }
      if (name === "email") {
        if (selectedPatient) {
          setSelectedPatient((prevPatient) => ({
            ...prevPatient,
            email: targetValue,
          }));
        } else {
          setUserDetails((prevState) => ({
            ...prevState,
            email: targetValue,
          }));
        }

      }
      if (name === "phone") {
        if (selectedPatient) {
          setSelectedPatient((prevPatient) => ({
            ...prevPatient,
            phoneNumber: targetValue,
          }));
        } else {
          setUserDetails((prevState) => ({
            ...prevState,
            phoneNumber: targetValue,
          }));
        }
      }
      if (name === "ssn") {
        if (selectedPatient) {
          setSelectedPatient((prevPatient) => ({
            ...prevPatient,
            socialSecurityNumber: targetValue,
          }));
        } else {
          setUserDetails((prevState) => ({
            ...prevState,
            socialSecurityNumber: targetValue,
          }));
        }

      }
      if (name == "memberid") {
        {
          if (selectedPatient) {
            setSelectedPatient((prevPatient) => ({
              ...prevPatient,
              memberId: targetValue,
            }));
          } else {
            setUserDetails((prevState) => ({
              ...prevState,
              memberId: targetValue,
            }));
          }

        }

      }
      if (name == "apptdate") {

        if (selectedPatient) {
          setSelectedPatient((prevPatient) => ({
            ...prevPatient,
            appointmentDate: targetValue,
          }));
        } else {
          setUserDetails((prevState) => ({
            ...prevState,
            appointmentDate: targetValue,
          }));
        }

      }


    }
    setInput(value);
    fetchData(value);
  };

  const handleChange1 = (event, value) => {
    if (event && event.target) {
      const { name, value: targetValue } = event.target;

      setUserDetails((prevState) => ({
        ...prevState,
        [name]: targetValue,
      }));
    }
    setInput(value);
    fetchData(value);
  };

  const searchbtn = async (e) => {
    e.preventDefault();

    if (emailError) {
      console.log("Validation error. Please correct the email.");
      return;
    }

    try {

      userDetails.dateOfService = "2024-06-05T05:29:57.269Z"
      userDetails.patientDOB = "2024-06-05T05:29:57.268Z"
      const postsearchdata = await post_discoverysearchdata(userDetails);

      navigate("/Searchresults", { state: { searchData: postsearchdata } });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async (value) => {
    try {
      let npiInfo = getLocalData('selectedNpi');
      const patientdata = await get_GetAllPatientsDataAsync(npiInfo?.id);

      const flattenedDataObjList = patientdata.patientDataList.map((patient) => ({
        firstName: patient.firstName,
        lastName: patient.lastName,
        dob: patient.dob,
      }));

      const results = flattenedDataObjList.filter((patient) => {
        return (
          value &&
          patient.firstName &&
          patient.lastName &&
          patient.dob &&
          (patient.firstName.toLowerCase().includes(value.toLowerCase()) ||
            patient.lastName.toLowerCase().includes(value.toLowerCase()) ||
            patient.dob.toLowerCase().includes(value.toLowerCase()))
        );
      });
      setSuggestions(results);
    } catch (error) {
      console.error("Error fetching patient data:", error);
    }
  };

  return (
    <Box>
      <Box>
        <Box>
          <FormControl fullWidth>
            <Autocomplete
              freeSolo
              options={suggestions.map((option) => `${option.firstName} ${option.lastName} - ${option.dob}`)}
              value={input}
              onChange={handleSelectionChange}
              onInputChange={handleChange1}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Search Patients"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton aria-label="search">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Box>
      </Box>
      <Box>
        <Box className={formWrapper}>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={{ marginBottom: "2em", color: "purple" }} fullWidth>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    name="FirstName"
                    onChange={handleChange}
                    color="error"
                    required
                    inputProps={{ maxLength: 20 }}
                    value={selectedPatient ? selectedPatient.patientFirstName : userDetails.patientFirstName}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    name="LastName"
                    onChange={handleChange}
                    color="error"
                    required
                    inputProps={{ maxLength: 20 }}
                    value={selectedPatient ? selectedPatient.patientLastName : userDetails.patientLastName}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                  <TextField
                    label="Email Address"
                    variant="outlined"
                    name="email"
                    onChange={handleChange}
                    color="info"
                    error={Boolean(emailError)}
                    helperText={emailError}
                    value={selectedPatient ? selectedPatient.email : userDetails.email}

                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                  <TextField
                    label="Patient Phone"
                    variant="outlined"
                    name="phone"
                    color="primary"
                    onChange={handleChange}
                    value={selectedPatient ? selectedPatient.phoneNumber : userDetails.phoneNumber}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                  <TextField
                    label="Appointment Date"
                    variant="outlined"
                    color="warning"
                    name="apptdate"
                    onChange={handleChange}
                    value={selectedPatient ? selectedPatient.appointmentDate : userDetails.appointmentDate}

                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of Birth"
                      variant="outlined"
                      name="PatientDOB"
                      value={selectedPatient ? dayjs(selectedPatient.patientDOB) : dayjs(userDetails.patientDOB)}

                      onChange={handleChange}
                      color="error"
                      required
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of service"
                      variant="outlined"
                      name="dateofservice"
                      onChange={handleChange}
                      value={selectedPatient ? dayjs(selectedPatient.dateOfService) : dayjs(userDetails.dateOfService)}
                      color="error"
                      required
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                  <TextField
                    label="Insurance Name"
                    variant="outlined"
                    color="warning"
                    name="insurance"
                    onChange={handleChange}
                    value={selectedPatient ? selectedPatient.insurancename : userDetails.insurancename}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                  <TextField
                    label="Member ID"
                    variant="outlined"
                    color="warning"
                    name="memberid"

                    onChange={handleChange}
                    value={selectedPatient ? selectedPatient.memberId : userDetails.memberId}

                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                  <TextField
                    label="Social Security Number"
                    variant="outlined"
                    color="warning"
                    name="ssn"

                    onChange={handleChange}
                    inputProps={{ maxLength: 10 }}
                    value={selectedPatient ? selectedPatient.socialSecurityNumber : userDetails.socialSecurityNumber}


                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} textAlign={"right"}>
                <Button label="Cancel" variant="outlined" color="inherit">
                  Cancel
                </Button>
              </Grid>
              <Grid item lg={6} textAlign={"left"}>
                <Button label="Search" variant="contained" color="success" onClick={searchbtn}>
                  Search
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default Search;
