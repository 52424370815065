import { colors } from '../../utils/colors';
export const styles = {
    graphContainer: {
        justifyContent: 'space-between', mb: 2
    },
    cardValue: {
        fontWeight: 'bold'
    },
    cardText: { marginY: 2 },
    filterButton:{color:colors?.themeGreen, border: `1px solid ${colors?.themeGreen}`},
    chartParentContainer:{
      width: '100%',
      height: '500px',
      padding: '2px', 
    },
    chartContainer: {
      boxShadow: `0px 3px 6px ${colors?.shadow}`, 
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      borderRadius: '4px', 
      padding: '8px',
      flexDirection: 'column',
    }
}

export const appointmentCalendarStyles = {
  calendarContainer: {
    height: '500px'
  },
  calendar: {
    height: '100%'
  },
  event: {
    backgroundColor: colors.themeGreen,
    color: colors.white,
    border: 'none'
  }
};

export const createEventModalStyles = {
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    width: 400,
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    my: 2,
  },
  formField: {
    mb: 2,
    width: '100%',
  },
  notesField: {
    mb: 3,
    width: '100%',
  },
  createButton: {
    width: '100%',
    backgroundColor: colors.themeGreen,
    '&:hover': {
      backgroundColor: colors.lightGreen, 
    },
  },
};

export const editEventModalStyles = {
    modalBox: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      width: 400,
      maxHeight: '90vh',
      overflowY: 'auto',
    },
    headerBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 2,
    },
    textField: {
      width: '100%',
      marginBottom: 2,
    },
    notesField: {
      width: '100%',
      marginBottom: 3,
    },
    updateButton: {
       width: '100%',
    backgroundColor: colors.themeGreen,
    '&:hover': {
      backgroundColor: colors.lightGreen, 
    },
    },
};

export const eventDetailsModalStyles = {
    modalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: colors.white,
        boxShadow: 24,
        p: 4,
        width: 400,
        maxHeight: '90vh',
        overflowY: 'auto',
        borderRadius: 2,
      },
      headerBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 2,
      },
      modalTitle: {
        variant: 'h6',
        component: 'h2',
      },
      detailsBox: {
        marginBottom: 2,
      },
      buttonBox: {
        marginTop: 2,
        display: 'flex',
        justifyContent: 'space-between',
      },
      button: {
        variant: 'contained',
      },
      editButton: {
        backgroundColor: colors.themeGreen, 
        color: colors.white,
        '&:hover': {
            backgroundColor: colors.lightGreen, 
        },
      },
      deleteButton: {
        backgroundColor: colors.lightRed,
        color: colors.white,
        '&:hover': {
          backgroundColor: colors.danger,
        },
      },
};