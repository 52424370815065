import { Autocomplete, Box, FormControl, IconButton, InputAdornment, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styles from '../css/cardheader.module.css'
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import { get_GetAllPatientsDataAsync } from '../services/addPatient';

const TableHeader = ({ setFilterPatient }) => {
    const [input, setInput] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const { searchWrapper } = styles;

    const fetchData = async (value) => {
        try {
            let npiInfo = JSON.parse(localStorage?.getItem('selectedNpi'));
            const patientdata = await get_GetAllPatientsDataAsync(npiInfo?.id);
            if (patientdata) {
                const flattenedDataObjList = patientdata?.patientDataList?.map(patient => ({
                    firstName: patient?.firstName,
                    lastName: patient?.lastName,
                    dob: patient?.dob
                }));

                const results = flattenedDataObjList?.filter(patient => {
                    return (
                        value &&
                        patient?.firstName &&
                        patient?.lastName &&
                        patient?.dob &&
                        (patient?.firstName?.toLowerCase()?.includes(value.toLowerCase()) ||
                            patient?.lastName?.toLowerCase()?.includes(value.toLowerCase()) ||
                            patient?.dob?.toLowerCase()?.includes(value.toLowerCase()))
                    );
                });
                setSuggestions(results);
            }
            //setSuggestions(flattenedDataObjList);
        } catch (error) {
            console.error('Error fetching patient data:', error);
        }
    };

    const handleChange = (event, value) => {
        setInput(value);
        fetchData(value);
        setFilterPatient(value);
    };

    useEffect(() => {
        fetchData(input); // Fetch data when component mounts
    }, []); // Empty dependency array ensures this runs once on mount

    return (
        <Box className={searchWrapper}>
            <Box className={searchWrapper}>
                <FormControl fullWidth>
                    <Autocomplete
                        freeSolo
                        options={suggestions?.map((option) => `${option?.firstName} ${option?.lastName} - ${option?.dob}`)}
                        value={input}
                        onInputChange={handleChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Search Patients"
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton aria-label="search">
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    />
                </FormControl>
                <IconButton>
                    <FilterListIcon />
                </IconButton>
            </Box>
        </Box>
    )
}

export default TableHeader