import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import { get_GetAllInsuranceDiscoveryData } from '../services/registration';
import React, { useEffect, useState } from 'react';
import { Button, } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const GridComponent = ({ pagename, searchData }) => {

  const [rowData, setRowData] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (pagename === "Discovery") {
          const data = await get_GetAllInsuranceDiscoveryData();
          if (Array.isArray(data.insuranceDiscoveryDtosList)) {
            const flattenedRows = data.insuranceDiscoveryDtosList.flatMap(element => {
              return element.patientData ? [
                {
                  ID: element?.patientId,
                  Transactiondate: element?.transactionDate || 'N/A',
                  Patient: `${element?.patientData?.firstName} ${element?.patientData?.lastName}`,
                  Phone: element.patientData.phone || 'N/A',
                  Copay: element?.planCoverageSummary ? element?.planCoverageSummary.policyType : 'N/A',
                  Deductible: element?.additionalInfo || 'N/A',
                  Insurance: element?.payerName || 'N/A',
                  Status: element?.planCoverageSummary ? element?.planCoverageSummary?.status : 'N/A',
                }
              ] : [];
            });
            setRowData(flattenedRows);
          }
        }
        else {
          const data = searchData;
          const flattenedRows = {
            ID: data.insuranceDiscoveryDto.patientData.id,
            Date: data.insuranceDiscoveryDto.patientData.appointments ? data.insuranceDiscoveryDto.patientData.appointments[0] : 'N/A',
            Patient: `${data.insuranceDiscoveryDto.patientData.firstName} ${data.insuranceDiscoveryDto.patientData.lastName}`,
            Phone: data.insuranceDiscoveryDto.patientData.phone || 'N/A',
            DOB: data.insuranceDiscoveryDto.patientData.dob || 'N/A',
            Insurance: data.insuranceDiscoveryDto.payerName || 'N/A',
            Copay: data.insuranceDiscoveryDto.additionalInfo || 'N/A', // Assuming additionalInfo contains Copay
            Status: data.insuranceDiscoveryDto.planCoverageSummary ? data.insuranceDiscoveryDto.planCoverageSummary.status : 'N/A',
          };
          setRowData([flattenedRows]);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };


    fetchData(); // Call fetchData function when component mounts
  }, []);



  const statusCellRenderer = (params) => {
    const statusValue = params.value;
    const statusStyle = {
      backgroundColor: '#F8FFF6', // Green background color
      color: '#229A16', // Green font color
    };
    return <span style={statusStyle}>{statusValue}</span>;
  };


  const DetailsButton = (params) => {
    return (
      <Button
        className='primary'
        style={{ backgroundColor: 'green', border: 'none', color: 'white', fontSize: 'small' }}
        onClick={() => handleButtonClick(params.data)}
      >
        View Details
      </Button>
    );
  };
  const handleButtonClick = (data) => {
    navigate({
      pathname: '/Discoverydetails1',
      search: `?rowData=${encodeURIComponent(JSON.stringify(data))}`,
    });
  };




  // Define column definitions based on the pagename prop
  const columnDefs = () => {
    if (pagename === "Discovery") {
      return [
      { headerName: 'ID#', field: 'ID', width: 150 },
      { headerName: 'Transaction Date', field: 'Transactiondate', sortable: true, filter: true },
      { headerName: 'Patient', field: 'Patient',sortable: true, filter: true  },
      { headerName: 'Phone', field: 'Phone',sortable: true, filter: true  },
      { headerName: 'Copay', field: 'Copay', width: 150,sortable: true, filter: true  },
      { headerName: 'Deductible', field: 'Deductible', width: 150, sortable: true, filter: true  },
      { headerName: 'Insurance', field: 'Insurance', width: 150 , sortable: true, filter: true },
      { headerName: 'Status', field: 'Status', sortable: true, filter: true ,cellRenderer: statusCellRenderer },
      { headerName: 'Details', field: 'Details', width: 150, filter: true, cellRenderer: DetailsButton, },
    ];
    }
    else {
      return [
        { headerName: 'ID#', field: 'ID', width: 150 },
        { headerName: 'Date', field: 'Date', sortable: true, filter: true },
        { headerName: 'Patient', field: 'Patient', filter: true },
        { headerName: 'Phone', field: 'Phone', filter: true },
        { headerName: 'Date of Birth', field: 'DOB', width: 150 },
        { headerName: 'Insurance Carrier', field: 'Insurance', width: 150, filter: true },
        { headerName: 'Status', field: 'Status', cellRenderer: statusCellRenderer, filter: true },
        { headerName: 'Details', field: 'Details', width: 150, filter: true, cellRenderer: DetailsButton, },
      ];
    }
  };

  const gridOptions = {
    defaultColDef: {
      flex: 1,
      minWidth: 200
    },
    // Enable pagination
    pagination: true,
    // Set the number of rows per page
    paginationPageSize: 10, // Adjust as needed
    // Optional: Specify the layout of pagination components
    domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
    // Other grid options...
  };

  return (
    <div className="ag-theme-quartz"
      style={{ height: 500 }}  >

      <AgGridReact
        columnDefs={columnDefs()}
        rowData={rowData}
        gridOptions={gridOptions}
      />
    </div>
  );
};

export default GridComponent;
