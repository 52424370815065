import React from 'react'
import PatientsClaimsChart from '../Dashboard/PatientsClaimsChart';
import { Box, Button, Typography } from '@mui/material';
import HorizontalBox from '../../components/shared/HorizontalBox';
import { styles } from './styles';
import ClaimDashboardCards from './ClaimDashboardCards';
import { sharedStyles } from '../../sharedStyles';

const ClaimDashboard = () => {
    return (
        <>
            <ClaimDashboardCards />
            <Box boxShadow={3} paddingY={2} paddingX={2} borderRadius={4}
                marginY={2}
            >
                <HorizontalBox sx={styles.graphContainer}>
                    <Typography variant="h5">
                        Patients Overview
                    </Typography>
                    <Button
                onClick={()=>{}}
                sx={sharedStyles?.filterButton}> Filter</Button>
                </HorizontalBox>
                <PatientsClaimsChart />
            </Box>
        </>
    )
}

export default ClaimDashboard


