import React, { useEffect, useState } from 'react'
import MyTabs from '../../components/tabs/MyTabs'
import { Box, Button } from '@mui/material'
import { GridComp } from '../../css/GridComponent';
import Eligibility from './Eligibility';
import Search from './Search';
import DentalSearch from './DentalSearch';

const EligibilityTab = () => {
   
     
    const tabs =
    [
        {
            label: "Eligibility ", value: <Eligibility />,
            button: 'New Search', changeTab: 1
        },
        {
            label: "Search", value: <Search />, changeTab: 0, button: 'Back',
        },
        {
            label: "Dental Search", value: <DentalSearch />, changeTab: 0, button: 'Back'
        },
    ]

    return (
        <section className='Eligibility'>
            <Box sx={{
                boxShadow: '0 0 2px 0 #919EAB',
                borderRadius: '12px'
            }}>
                <MyTabs tabs={tabs} />
            </Box>
        </section>
    )
}
export default EligibilityTab