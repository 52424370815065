import React, { useState, useEffect } from "react";
import SelectTwo from "../../components/select/SelectTwo";
import {
  Box,
  FormControl,
  InputAdornment,
  IconButton,
  Button,
  Grid,
  Autocomplete,
  CircularProgress,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styles from "../../css/addpatient.module.css";
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { getLocalData } from "../../utils/localStorageFunctions";
import { defaultFormDetails, defaultSelectedPatientRequest } from "../../utils/claimDefualtValues";
import { defaultEligibilityUserDetails } from "../../utils/eligibilityDefaultValues";
import { ymdDateFormat } from "../../utils/DateFunctions";
import toast from "react-hot-toast";
import { get_GetAllPatientsDataAsync, post_Patient } from "../../services/addPatient";
import { post_NpiDataByNpiId, get_NpiDataByNpiNumber } from "../../services/ClaimService";
import { get_GetAllPayersData, get_GetPayerCodeByName } from "../../services/PayerService";
import { post_dentaleligibility } from "../../services/DentalEligibilityService";
import FilterListIcon from '@mui/icons-material/FilterList';
import customStyles from "../../css/DentalSearchStyles.css"; // Import the CSS module

const DentalSearch = () => {
    const [searchInput, setSearchInput] = useState("");
    const navigate = useNavigate();
    const { formWrapper } = styles;
    const [payersData, setPayersData] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [insurance, setInsurance] = useState([]);
    const [npis, setNpis] = useState([]);
    const [maxDate, setMaxDate] = useState(() => {
        const date = new Date();
        date.setDate(date.getDate() - 14);
        return date;
    });
    //Default npi from user logged in : from browsers storage 
    const defaultNpis = getLocalData('user')?.doctorNpis ?? '';
    const currentNpi = getLocalData('user')?.doctorNpis[0]?.toString() ?? ''
    const [formDetails, setformDetails] = useState({
        ...defaultFormDetails,
        NPI: currentNpi
    });
    const [isLoading, setIsLoading] = useState(false)
    //Request format
    const [userDetails, setUserDetails] = useState(defaultEligibilityUserDetails());
    const handleChange1 = (event, value) => {
        if (value !== null) {
            setSearchInput(value);
            fetchData(value);
        }
    };
    const DropDownHandle = (event, newValue) => {
        setInsurance(newValue)
        setformDetails((prevDetails) => ({
            ...prevDetails,
            PatientInsurancePayer: newValue
        }));
    };
    const DropDownNpis = (event, newValue) => {
        if (newValue === null) {
            setNpis(selectedPatient ? selectedPatient?.NPI : defaultNpis)
            newValue = currentNpi
        }
        else {
            setNpis([newValue])
        }
        setformDetails((prevDetails) => ({
            ...prevDetails,
            NPI: newValue,
        }));
    };
    const handleSelectionChange = async (event, newValue) => {
        var selected_patient_request = defaultSelectedPatientRequest();
        if (newValue === null) {
            setSelectedPatient(null);
            setInsurance([])
            setNpis(defaultNpis)
            setformDetails({
                ...defaultFormDetails,
                NPI: currentNpi
            });
            return;
        }
        const [namePart, dobPart] = newValue?.split('-') ?? [];
        const [firstName1, lastName1] = namePart?.split(' ') ?? [];
        const dobPart1 = dobPart;
        try {
            let npiInfo = getLocalData("selectedNpi");
            const patientdata = await get_GetAllPatientsDataAsync(npiInfo?.id);
            let specificData = null; 
            for (let i = 0; i < (patientdata?.patientDataList?.length ?? 0); i++) {
                const patient_record = patientdata?.patientDataList[i];
                if (firstName1 === patient_record?.firstName &&
                    lastName1 === patient_record?.lastName
                ) {
                    specificData = patient_record;
                    break;
                }
            }
            selected_patient_request.PatientInsurancePayer = specificData?.patientInsuranceDetails ?? [];
            selected_patient_request.PatientFirstName = specificData?.firstName ?? "";
            selected_patient_request.LastName = specificData?.lastName ?? "";
            selected_patient_request.dateofservice = ymdDateFormat(new Date());
            selected_patient_request.dob = specificData?.dob ?? "";
            selected_patient_request.memberId = specificData?.memberId ?? "";
            selected_patient_request.phone = specificData?.phone ?? "";
            userDetails.subscriber.dob = dobPart1;
            selected_patient_request.NPI = currentNpi;
            setSelectedPatient(selected_patient_request)
            setformDetails(selected_patient_request)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const handleChange = (event, value) => {
        if (event && event.target) {
            const { name, value: targetValue } = event.target;
            if (name === "PatientFirstName") {
                setUserDetails(prevState => ({
                    ...prevState,
                    subscriber: {
                        ...prevState.subscriber,
                        FirstName: targetValue
                    }
                }));

                setformDetails((prevDetails) => ({
                    ...prevDetails,
                    PatientFirstName: targetValue,
                }));
            }
            else if (name === "LastName") {
                setUserDetails(prevState => ({
                    ...prevState,
                    subscriber: {
                        ...prevState.subscriber,
                        lastName: targetValue
                    }
                }));

                setformDetails((prevDetails) => ({
                    ...prevDetails,
                    LastName: targetValue,
                }));
            }
            else if (name === "NPI") {
                setformDetails((prevDetails) => ({
                    ...prevDetails,
                    NPI: targetValue,
                }));
            }
            else if (name === "dateofservice") {
                setformDetails((prevDetails) => ({
                    ...prevDetails,
                    dateofservice: ymdDateFormat(dayjs(targetValue)),
                }));
            }
            else {
                setformDetails((prevDetails) => ({
                    ...prevDetails,
                    [name]: targetValue,
                }));
            }
        }
        fetchData(value);
    };
    const searchbtn = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            if (!selectedPatient || selectedPatient?.PatientFirstName !== formDetails?.PatientFirstName || selectedPatient?.LastName !== formDetails?.LastName) {
                let npiInfo = getLocalData('selectedNpi');
                const patientdata = await get_GetAllPatientsDataAsync(npiInfo?.id);
                let specificData = null;
                if (patientdata?.patientDataList?.length > 0) {
                    patientdata.patientDataList.forEach(patient_record => {
                        if (formDetails?.PatientFirstName === patient_record?.firstName &&
                            formDetails?.LastName === patient_record?.lastName) {
                            specificData = patient_record;
                            return;
                        }
                    });
                }

                if (specificData) {
                    setformDetails(prevState => ({
                        ...prevState,
                        memberId: specificData?.memberId ?? "",
                        dob: specificData?.dob ?? ""
                    }));
                }
            }

            const npidatabynumber = await get_NpiDataByNpiNumber(formDetails?.NPI);
            const payerNameCodePair = {};
            const payerCodes1 = [];
            await Promise.all((formDetails?.PatientInsurancePayer || [])?.map(async (i) => {
                const payerInfo = await get_GetPayerCodeByName(i);
                if (payerInfo?.[0]) {
                    payerNameCodePair[payerInfo[0]?.payerName] = payerInfo[0]?.payerCode;
                    payerCodes1.push(payerInfo[0]?.payerCode);
                }
            }));

            setformDetails((prevDetails) => ({
                ...prevDetails,
                payerCodes: payerCodes1 ?? [],
            }));

            const updatedDetails = {
                ...userDetails,
                IncludeHtmlResponse: "True",
                Location: "VA",
                PracticeTypeCode: "0",
                ProviderLastName: npidatabynumber?.npiData?.providerLastName ?? '',
                ProviderNPI: formDetails?.NPI?.toString() ?? '',
                dependent: "null",
                doS_StartDate: dayjs(formDetails?.dateofservice).subtract(5, 'day').format('MM/DD/YYYY'),
                doS_EndDate: dayjs(formDetails?.dateofservice).add(5, 'day').format('MM/DD/YYYY'),
                isSubscriberPatient: "False",
                payerCode: payerCodes1[0] ?? '',
                payerCodes: payerCodes1 ?? [],
                payerName: formDetails?.PatientInsurancePayer?.[0] ?? '',
                provider: {
                    ...userDetails.provider,
                    firstName: npidatabynumber?.npiData?.providerFirstName ?? '',
                    middleName: " ",
                    lastName: npidatabynumber?.npiData?.providerLastName ?? '',
                    npi: formDetails?.NPI?.toString() ?? '',
                    TaxId: "12345"
                },
                referenceId: "0",
                subscriber: {
                    ...userDetails.subscriber,
                    memberID: formDetails?.memberId ?? '',
                    FirstName: formDetails?.PatientFirstName ?? '',
                    firstName: formDetails?.PatientFirstName ?? '',
                    lastName: formDetails?.LastName ?? '',
                    dob: formDetails?.dob ?? '',
                },
            };
            setUserDetails(updatedDetails);
            if (!formDetails?.PatientFirstName || !formDetails?.LastName  || !formDetails?.dateofservice || !formDetails?.NPI) {
                toast.error("Enter all the required fields");
            } else {
                const postsearchdata = await post_dentaleligibility(updatedDetails);
                navigate("/DentalSearchresults", { state: { searchData: postsearchdata??"" } });
            }
        } catch (error) {
            toast.error('Error fetching data');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchData = async (value) => {
        try {
            let npiInfo = getLocalData('selectedNpi');
            const patientdata = await get_GetAllPatientsDataAsync(npiInfo?.id);
            const flattenedDataObjList = patientdata?.patientDataList?.map(patient => ({
                firstName: patient?.firstName,
                lastName: patient?.lastName,
                dob: patient?.dob
            })) ?? [];

            const results = flattenedDataObjList.filter(patient => {
              return (
                value &&
                patient?.firstName &&
                patient?.lastName &&
                patient?.dob &&
                (patient.firstName?.toLowerCase()?.includes(value.toLowerCase()) ||
                  patient.lastName?.toLowerCase()?.includes(value.toLowerCase()) ||
                  patient.dob?.toLowerCase()?.includes(value.toLowerCase()))
              );
            });

            setSuggestions(results);
        } 
        catch (error) {
            console.error('Error fetching patient data:', error);
        }
    };
    const PayersDropdown = async () => {
        const payersdata = await get_GetAllPayersData();
        var payers_array = [];
        for (let i = 0; i < (payersdata?.length ?? 0); i++) {
            payers_array.push(payersdata[i]?.payerName);
        }
        setPayersData(payers_array)
    };
    useEffect(() => {
        PayersDropdown();
        setNpis(defaultNpis ?? [])
        if (formDetails?.PatientInsurancePayer) {
            setInsurance(formDetails.PatientInsurancePayer);
        }
    }, []);

  return (
    <Box>
      <Box className={customStyles.searchContainer}>
        <Box className={customStyles.searchInnerContainer}>
          <FormControl fullWidth>
          <Autocomplete
                            freeSolo
              options={suggestions.map((option) =>
                `${option?.firstName ?? ''} ${option?.lastName ?? ''} - ${option?.dob ?? ''}`
              )}
                            value={searchInput}
                            name="search-drop"
                            onInputChange={handleChange1}
                            onChange={handleSelectionChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Search Patients"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton aria-label="search">
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            )}
                        />
          </FormControl>
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Box>
      </Box>
      <Box className={customStyles.marginTop2em}>
        <Box className={formWrapper}>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl className={customStyles.firstNameField} fullWidth>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    name="PatientFirstName"
                    onChange={handleChange}
                    color="error"
                    required
                    inputProps={{
                      maxLength: 20,
                      className: 'capitalize-text',
                    }}
                    value={formDetails?.PatientFirstName ?? ""}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl className={customStyles.lastNameField} fullWidth>
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    name="LastName"
                    onChange={handleChange}
                    color="error"
                    required
                    inputProps={{
                      maxLength: 20,
                      className: 'capitalize-text',
                    }}
                    value={formDetails?.LastName ?? ""}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl className={customStyles.insuranceField} fullWidth>
                  <Autocomplete
                    multiple
                    options={payersData}
                    name="InsurancePayer"
                    getOptionLabel={(option) => option}
                    value={
                      formDetails.PatientInsurancePayer
                        ? formDetails.PatientInsurancePayer
                        : null
                    }
                    onChange={DropDownHandle}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Insurance Name"
                        variant="outlined"
                        color="success"
                        helperText="Select insurance names"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl className={customStyles.dateOfServiceField} fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of service"
                      onChange={(val) =>
                        handleChange({
                          target: {
                            name: "dateofservice",
                            value: dayjs(new Date(val)?.toISOString()),
                          },
                        })
                      }
                      name="dateofservice"
                      maxDate={dayjs(maxDate)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          color="error"
                          required
                          inputProps={{
                            ...params.inputProps,
                            maxLength: 20,
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl className={customStyles.npiField} fullWidth>
                  <TextField
                    label="NPI"
                    variant="outlined"
                    name="NPI"
                    color="primary"
                    onChange={handleChange}
                    value={formDetails?.NPI ?? currentNpi}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                lg={6}
                className={`${customStyles.textAlignLeft} ${customStyles.paddingTop140px}`}
              >
                <Button
                  label="Search"
                  variant="contained"
                  color="success"
                  className={customStyles.searchButton}
                  onClick={searchbtn}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress
                      className={customStyles.circularProgress}
                    />
                  ) : (
                    "Search"
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Box>
  );
};
export default DentalSearch;