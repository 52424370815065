import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import GridComponentDentalEligibility from '../../css/GridComponentDentalEligibility';
import styles from '../../css/DentalSearchResultData.styles.css';

const DentalSearchresultdata = () => {
    const { headerContainer, patientName, marginTop2em } = styles;
    const pagename = "DentalSearchresultdata";
    const location = useLocation();
    const searchData = location.state?.searchData || [];

    return (
        <Box>
            <Box className={headerContainer}>
                <Box className={patientName}>
                    <p> John Smith</p>
                </Box>
            </Box>

            <Box className={marginTop2em}>
                {/* Additional content can go here */}
            </Box>

            <GridComponentDentalEligibility
                pagename={pagename}
                searchData={searchData}
            />
        </Box>
    );
}

export default DentalSearchresultdata;
