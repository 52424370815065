import React, { useState } from 'react'
import SelectTwo from '../../components/select/SelectTwo'
import { Box, FormControl, Input, InputAdornment, IconButton, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PaginationWrapper from '../../components/pagination/PaginationWrapper';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FilterListIcon from '@mui/icons-material/FilterList';
import GridComponent from '../../css/GridComponent';
import TableHeader from '../../components/TableHeader';


const Discovery = () => {
  const pagename = "Discovery";
  const [filterPatient, setFilterPatient] = useState("");
  return (
    <Box>
      <Box>
        <TableHeader setFilterPatient={setFilterPatient} />
      </Box>
      <Box sx={{ marginTop: '2em' }}>
        <GridComponent pagename={pagename} filterPatient={filterPatient} />
      </Box>
    </Box>
  )
}
export default Discovery