import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { createEventModalStyles } from './styles';

const CreateEventModal = ({ open, onClose, onCreateEvent, selectedDate }) => {
  const [title, setTitle] = useState('');
  const [start, setStart] = useState(selectedDate ?? new Date());
  const [end, setEnd] = useState(
    selectedDate
      ? new Date(selectedDate?.getTime() + 60 * 60 * 1000)
      : new Date(new Date().getTime() + 60 * 60 * 1000)
  );
  const [patientName, setPatientName] = useState('');
  const [notes, setNotes] = useState('');

  useEffect(() => {
    if (selectedDate) {
      setStart(new Date(selectedDate));
      setEnd(new Date(selectedDate?.getTime() + 60 * 60 * 1000));
    }
  }, [selectedDate]);

  const handleCreateEvent = () => {
    onCreateEvent?.({
      title,
      start,
      end,
      patientName,
      notes
    });
    resetForm();
  };

  const resetForm = () => {
    setTitle('');
    setStart(new Date());
    setEnd(new Date(new Date().getTime() + 60 * 60 * 1000));
    setPatientName('');
    setNotes('');
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={createEventModalStyles?.modalBox}>
        <Box sx={createEventModalStyles?.headerBox}>
          <Typography variant="h5" component="h2">
            Create Appointment
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={createEventModalStyles?.divider} />
        <TextField
          label="Patient Name"
          value={patientName ?? ''}
          onChange={(e) => setPatientName(e?.target?.value ?? '')}
          sx={createEventModalStyles?.formField}
        />
        <TextField
          label="Appointment Title"
          value={title ?? ''}
          onChange={(e) => setTitle(e?.target?.value ?? '')}
          sx={createEventModalStyles?.formField}
        />
        <TextField
          label="Start"
          type="datetime-local"
          value={moment(start).format('YYYY-MM-DDTHH:mm')}
          onChange={(e) => setStart(new Date(e?.target?.value ?? new Date()))}
          sx={createEventModalStyles?.formField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="End"
          type="datetime-local"
          value={moment(end).format('YYYY-MM-DDTHH:mm')}
          onChange={(e) => setEnd(new Date(e?.target?.value ?? new Date()))}
          sx={createEventModalStyles?.formField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="Notes"
          value={notes ?? ''}
          onChange={(e) => setNotes(e?.target?.value ?? '')}
          multiline
          rows={3}
          sx={createEventModalStyles?.notesField}
        />
        <Button 
          variant="contained" 
          onClick={handleCreateEvent}
          sx={createEventModalStyles?.createButton}
        >
          Create Appointment
        </Button>
      </Box>
    </Modal>
  );
};

export default CreateEventModal;