import { colors } from "../../utils/colors";

export const styles = {
    backButton: { background: colors?.surfaceLighter },
    backButtonContainer: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '1em', marginBottom: '1em' },
    npiDetailContainer: { padding: '1em', borderRadius: '12px', width: '100%', height: '100%' },
    linkDefault: { textDecoration: 'none', color: colors?.white, display: 'flex', alignItems: 'center', gap: '0.5em' },
    whiteBtn: {
        background: colors?.white, color: colors?.black, border: `1px solid ${colors?.themeDarkBlue}`, boxShadow: 0, '&:hover': {
            backgroundColor: colors?.surface,
            color: colors?.black,
        },
    },
    formControlStyles: {
        marginBottom: '2em'
    },
    textFieldStyles: {
        '& .MuiOutlinedInput-notchedOutline ': {
            borderRadius: '12px',
        }
    },
    borderRadius: { borderRadius: '12px' },
    paperPropsStlye: {
        maxHeight: 300,
        overflowY: 'auto',
    },
    searchBtn: { borderRadius: '10px', background: colors?.themeGreen },
    btnWrapper: { display: "flex", gap: '1.5em', justifyContent: "center" },
    cardStyles: {
        boxShadow: '0px 0px 12px 6px #eee',
        width: '100%',
        height: '100%',
        padding: '0.5em 1em',
        wordWrap: 'breakword',
        borderRadius: '12px'
    },
    boxStyles: {
        display: 'flex',
        alignItems: ['center', 'center'],
        justifyContent: ['center', 'flex-start'],
        gap: ['0.5em', '1em'],
        flexWrap: ['wrap', 'nowrap'],
    },
    Typography: {
        fontSize: ["12px", "14px"],
        color: colors?.themeGreen
    },
    innerCardHeader: {
        textAlign: 'center', borderBottom: `1px solid ${colors?.white}`, color: colors?.white, marginBottom: '1em', background:`${colors?.themeGreen}`
    },
    line: {
        lineHeight: 1.5,
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        fontWeight:'semibold',
        fontSize:'14px'
    }
};