
import { Box, CircularProgress, FormControl, Grid, Autocomplete, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import { isAlphabetOnly, isAlphaNumeric } from "../../utils/regexTestFunctions";
import { MenuProps, policyHolderRelationship } from "../../utils/staticData";
import React, { useState, useEffect } from "react";
import styles from "../../css/addpatient.module.css";
import TableHeader from "../../components/TableHeader";
import Button from "@mui/material/Button";
import { post_Patient } from "../../services/addPatient";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { get_GetAllPayersData } from "../../services/PayerService";
import { getLocalData } from "../../utils/localStorageFunctions";
import { phoneNumberFormat } from "../../utils/valueFormatter/Formats";
import toast from "react-hot-toast";

const AddPatient = () => {
  const [locations, setLocations] = useState("All Locations");
  const [payersData, setpayersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fieldsErr, setFieldsErr] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [patientDetails, setPatientDetails] = useState({
    patientLastName: "",
    patientFirstName: "",
    patientDOB: null,
    phoneNumber: "",
    email: "",
    appointmentDate: null,
    insurancename: [],
    memberId: "",
    groupNumber: "",
    policyHolderRelationship: "",
    requestNpi: ""
  });

  const { patientFormWrapper } = styles;



  const fetchData = async () => {
    try {
      const payersdata = await get_GetAllPayersData();
      var payers_array = [];
      for (let i = 0; i < payersdata.length; i++) {
        payers_array.push(payersdata[i].payerName)
      }
      setpayersData(payers_array)
    } catch (error) {
      console.error('Error fetching patient data:', error);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data when component mounts
  }, []);

  const handleChange = (e) => {
    if (e.target && e.target.name) {
      const { name, value } = e.target;
      // Validations
      if ((name === 'memberId' || name === 'groupNumber')) {
        if (!isAlphaNumeric(value)) {
          toast.error('This field only accepts Alphanumeric Characters');
          return;
        }
      } else if ((name === 'patientFirstName' || name === 'patientLastName')) {
        if (!isAlphabetOnly(value)) {
          toast.error('This field only accepts Alphabets.');
          return;
        }
      } else if (name === 'phoneNumber') {
        let formattedValue = phoneNumberFormat(value);
        setPatientDetails((prevDetails) => ({
          ...prevDetails,
          [name]: formattedValue,
        }));
        return;
      }
      setPatientDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };


  const handleInsuranceChange = (event, newValue) => {
    setPatientDetails((prevState) => ({
      ...prevState,
      insurancename: newValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      patientFirstName, patientLastName, email, patientDOB, memberId, groupNumber, appointmentDate, insurancename, phoneNumber, policyHolderRelationship, requestNpi,
    } = patientDetails;

    if (!patientFirstName || !patientLastName || !patientDOB) {
      setFieldsErr(true);
      return;
    }
    const requiredFieldsProvided = patientFirstName && patientLastName && patientDOB;
    const optionalFieldsProvided = memberId || groupNumber || appointmentDate || insurancename || policyHolderRelationship || phoneNumber || email;
    let payload = {};

    if (requiredFieldsProvided) {
      // If required fields are provided only then process this payload
      payload = {
        patientFirstName,
        patientLastName,
        patientDOB,
        requestNpi: getLocalData('user')?.doctorNpis[0]?.toString() ?? '',
      };

      // If any optional field is provided then process this payload.
      if (optionalFieldsProvided) {
        payload = {
          ...payload,
          patientDOB,
          memberId,
          groupNumber,
          appointmentDate,
          insurancename: insurancename.join(", "),
          phoneNumber,
          policyHolderRelationship,
          email,
          phoneNumber,
        };
      }
    }
    setIsLoading(true);
    try {
      const addPatient = await post_Patient(payload);
      if (addPatient?.data?.message) {
        toast.success(addPatient?.data?.message);
        setPatientDetails({
          patientLastName: "",
          patientFirstName: "",
          patientDOB: null,
          phoneNumber: "",
          email: "",
          appointmentDate: null,
          insurancename: [],
          memberId: "",
          groupNumber: "",
          policyHolderRelationship: "",
          requestNpi: "",
        });
        setFieldsErr(false);
      }
    } catch (err) {
      if (err?.response?.status === 400) {
        toast.error('Response ended with a 400 Error');
      } else if (err?.response?.status === 500) {
        toast.error('Something went wrong Bad Gateway');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const clear = () => {
    setPatientDetails({
      patientLastName: "",
      patientFirstName: "",
      patientDOB: null,
      phoneNumber: "",
      email: "",
      appointmentDate: null,
      insurancename: [],
      memberId: "",
      groupNumber: "",
      policyHolderRelationship: "",
      requestNpi: "",
    });
    setFieldsErr(false);
  };


  return (
    <div>
      <TableHeader locations={locations} setLocations={setLocations} />
      <Box className={patientFormWrapper}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={6}>
              <FormControl
                sx={{ marginBottom: "2em", color: "purple" }}
                fullWidth
              >
                <TextField
                  label="First Name"
                  id="outlined-start-adornment"
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline ': {
                      borderRadius: '12px',
                    }
                  }}
                  inputProps={{
                    maxLength: 20,
                    minLength: 2,
                    style: { textTransform: 'capitalize' }
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                  variant="outlined"
                  onChange={handleChange}
                  value={patientDetails.patientFirstName}
                  name="patientFirstName"
                  color="success"
                  helperText={fieldsErr && !patientDetails?.patientFirstName ? 'This field is required' : 'Required*'}
                  error={fieldsErr && !patientDetails?.patientFirstName}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <TextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                  inputProps={{
                    maxLenght: 20,
                    minLength: 2,
                    style: { textTransform: 'capitalize' }
                  }}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline ': {
                      borderRadius: '12px'
                    }
                  }}
                  onChange={handleChange}
                  value={patientDetails.patientLastName}
                  name="patientLastName"
                  label="Last Name"
                  variant="outlined"
                  color="success"
                  helperText={fieldsErr && !patientDetails?.patientLastName ? 'This field is required' : 'Required*'}
                  error={fieldsErr && !patientDetails?.patientLastName}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline ': {
                        borderRadius: '12px',
                        borderColor: (!patientDetails.patientDOB && fieldsErr) && 'red'
                      }
                    }}
                    label="Date of Birth"
                    onChange={(val) =>
                      setPatientDetails({
                        ...patientDetails,
                        patientDOB: val,
                      })
                    }
                    name={"patientDOB"}
                    value={patientDetails?.patientDOB}
                    variant="outlined"
                    color="success"
                  />
                </LocalizationProvider>
                <Typography fontSize={'13px'} color={!patientDetails.patientDOB && fieldsErr ? 'red' : '#00000099'} paddingLeft={"1em"} paddingTop={"0.2em"}>{!patientDetails.patientDOB && fieldsErr ? 'The field is required' : 'Required*'} </Typography>

              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <TextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}

                  sx={{
                    '& .MuiOutlinedInput-notchedOutline ': {
                      borderRadius: '12px',
                    }
                  }}
                  inputProps={{
                    inputMode: 'numeric'
                  }}
                  onChange={handleChange}
                  value={patientDetails?.phoneNumber}
                  name="phoneNumber"
                  label="Phone Number"
                  variant="outlined"
                  type="tel"
                  color="success"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <TextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline ': {
                      borderRadius: '12px',
                    }
                  }}
                  onChange={handleChange}
                  value={patientDetails?.email}
                  name="email"
                  label="Email"
                  type="email"
                  variant="outlined"
                  color="success"
                  error={emailError ? true : false}
                  helperText={emailError}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline ': {
                        borderRadius: '12px',
                      }
                    }}
                    label="Appointment Date"
                    onChange={(val) =>
                      setPatientDetails({
                        ...patientDetails,
                        appointmentDate: val,
                      })
                    }
                    name={"appointmentDate"}
                    value={patientDetails?.appointmentDate}
                    variant="outlined"
                    color="success"
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <Autocomplete
                  multiple
                  options={payersData}
                  getOptionLabel={(option) => option}
                  filterSelectedOptions
                  value={patientDetails?.insurancename}
                  onChange={handleInsuranceChange}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline ': {
                      borderRadius: '12px',
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Insurance Name"
                      variant="outlined"
                      color="success"
                      helperText="Select insurance names"
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <TextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>
                  }}
                  inputProps={{
                    maxLength: 30,
                    minLength: 2,
                    pattern: '^[a-zA-Z0-9]*$'
                  }}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline ': {
                      borderRadius: '12px',
                    }
                  }}
                  onChange={handleChange}
                  value={patientDetails?.memberId}
                  name="memberId"
                  label="Member ID"
                  variant="outlined"
                  color="success"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <TextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline ': {
                      borderRadius: '12px',
                    }
                  }}
                  onChange={handleChange}
                  value={patientDetails.groupNumber}
                  name="groupNumber"
                  label="Group Number"
                  variant="outlined"
                  color="success"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                <InputLabel id="payer-collection">Policy Holder Relationship</InputLabel>
                <Select
                  id="policy-holders"
                  onChange={(e) => setPatientDetails({ ...patientDetails, policyHolderRelationship: e.target.value })}
                  value={patientDetails?.policyHolderRelationship}
                  input={<OutlinedInput label="Policy Holder Relationship" />}
                  MenuProps={MenuProps}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline ': {
                      borderRadius: '12px',
                    },
                    '& .MuiGrid-root': {
                      height: '30px'
                    }
                  }}
                >
                  {
                    policyHolderRelationship?.length && policyHolderRelationship?.map((item, index) => (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Box sx={{ display: "flex", justifyContent: "center", gap: '2em' }}>
                <Button sx={{
                  background: 'white', color: 'black', border: '1px solid #0000003b', boxShadow: 0, '&:hover': {
                    backgroundColor: 'lightgray',
                    color: 'black',
                  },
                }} onClick={clear} variant="contained" disabled={isLoading} style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }} size="large">
                  Clear
                </Button>
                <Button type="submit" disabled={isLoading} sx={{ borderRadius: '10px', background: '#5e8a75', cursor: isLoading ? 'not-allowed' : 'pointer' }} variant="contained" size="small">
                  {isLoading ? <CircularProgress /> : 'Save'}
                </Button>
              </Box>
            </Grid>

          </Grid>
        </form>
      </Box>
    </div>
  );
};

export default AddPatient;
