export const colors = {
    themeGreen: '#5E8A75',
    lightGray: '#F8F8FF',
    gray: '#E5E5EF',
    danger: 'red',
    lightGreen: 'rgba(54, 162, 235, 0.2)',
    lightYellow: 'rgba(255, 206, 86, 0.2)',
    yellow: 'rgba(255, 206, 86, 1)',
    lightRed: 'rgba(255, 99, 132, 0.2)',
    black: '#000',
    surface: 'lightgray',
    surfaceLighter: '#eee',
    white: '#fff',
    themeBlue: '#1976d2',
    themeDarkBlue: '#0000003b0',
    themeDarkGrayishBlue: '#919EAB',
    red: '#cf3917',
    shadow:'#00000029'
}