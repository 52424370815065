import React, { useEffect, useState } from 'react'
import MyTabs from '../../components/tabs/MyTabs'
import { Box, Button } from '@mui/material'
import { GridComp } from '../../css/GridComponent';
import Claim from './Claim';
import ClaimSearch from './ClaimSearch';
import { useSearchParams } from 'react-router-dom';
import ClaimDashboard from './ClaimDashboard';
import  "../../css/ClaimSearchStyles.css";

const Claimtab = () => {
    const [searchParams] = useSearchParams();
    const preSelectedTabParam = searchParams.get('preSelectedTab');
    const [preSelectedTab] = useState(preSelectedTabParam ?? null)

    const tabs =
        [
            {
                label: "Dashboard ", value: <ClaimDashboard />,
                button: 'New Search', changeTab: 2
            },
            {
                label: "Claim ", value: <Claim />,
                button: 'New Search', changeTab: 2
            },
            {
                label: "Search", value: <ClaimSearch />, changeTab: 0, button: 'Back'
            },
        ]
    return (
        <section className='claim'>
        <Box className='customBox'>
            <MyTabs tabs={tabs} preSelectedTab={Number(preSelectedTab)} />
            {/* <Button variant='text'>Add Patient</Button> */}
        </Box>
    </section>
    )
}
export default Claimtab