import { colors } from "./utils/colors";

export const sharedStyles = {
    cursorPointer: { cursor: 'pointer' },
    bm1em: { marginBottom: '1em' },
    horizontalCenteredFlex:{ display: 'flex', justifyContent: 'center', alignItems: 'center'},
    filterButton:{color:colors?.themeGreen, border: `1px solid ${colors?.themeGreen}`},
    fullWidth: { width: '100%' },
    justifyBetween: { justifyContent: 'space-between' },
    justifyCenter: { justifyContent: 'center' }
};
