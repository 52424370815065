import { colors } from "../../utils/colors";

export const styles = {
    headerContainer: { justifyContent: 'space-between' },
    card: { cursor: 'pointer' },
    cardText: { marginY: 2 },
    cardValue: { fontWeight: 'bold' },
    greenCardValue: { fontWeight: 'bold', color: colors?.themeGreen },
    filterButton:{color:colors?.themeGreen, border: `1px solid ${colors?.themeGreen}`},
    datePicker:{marginY:2,width:'100%'}
}