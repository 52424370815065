import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../../css/addpatient.module.css";
import {
  post_Patient,
  get_GetPatientDataById,
} from "../../services/addPatient";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useParams } from "react-router-dom";
import ViewPatientTab from "../../components/tabs/ViewPatientDetailsTab";
import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";
import { getLocalData } from "../../utils/localStorageFunctions";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { colors } from "../../utils/colors";
import { formatDate } from "../../utils/DateFunctions";
import { deafultPatientDetails } from "../../utils/staticData";
import { getLastElementFromArray } from "../../utils/reusableFunctions";
import patientDetailsStyle from "../../css/patientdetails.module.css";
import ActionButton from "../../components/buttons/ActionButton";
import buttonStyles from "../../css/patientDetail.module.css";

const PatientDetail = ({ isEditable = false }) => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [fieldsErr, setFieldsErr] = useState(false);
  const [patientDetails, setPatientDetails] = useState({
    ...deafultPatientDetails,
  });
  const [editingEnabled, setEditingEnabled] = useState(isEditable);
  const { formWrapper } = styles;
  const navigate = useNavigate();
  const [tabName, setTabName] = useState("View Patient Detail");
  const { formMargin, cursor, editSection, editPatient, patientFirstNameField, mainDiv } = patientDetailsStyle;

  useEffect(() => {
    getUser();
  }, []);

  const toggleEditing = () => {
    setEditingEnabled((prevState) => !prevState);
    let tabName = !editingEnabled ? "Edit Patient" : "View Patient Detail";
    setTabName(tabName);
  };

  const renderEditIcon = () => {
    if (!editingEnabled) {
      return (
        <EditIcon
          onClick={toggleEditing}
          fontSize="small"
          className={cursor}
        />
      );
    }
  };

  const getUser = async () => {
    try {
      const item = await get_GetPatientDataById(id);

      if (item?.patientInfoData) {
        setPatientDetails({
          patientId: item.patientInfoData?.id,
          patientFirstName: item.patientInfoData?.firstName,
          patientLastName: item.patientInfoData?.lastName,
          patientDOB: item.patientInfoData?.dob,
          phoneNumber: item.patientInfoData?.phone,
          email: item.patientInfoData?.email,
          appointmentDate: item.patientInfoData?.appointments
            ? getLastElementFromArray(item.patientInfoData.appointments)
            : null,
          insurancename: item.patientInfoData?.patientInsuranceDetails
            ? getLastElementFromArray(item.patientInfoData.patientInsuranceDetails)
            : null,
          memberId: item.patientInfoData?.memberId,
          groupNumber: item.patientInfoData?.groupNumber,
          policyHolderRelationship:
            item.patientInfoData?.policyHolderRelationship,
        });
      }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    if (e?.target && e?.target?.name) {
      const { name, value } = e?.target;
      const newValue = name === "requestNpi" ? parseInt(value) : value;
      setPatientDetails((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      patientId,
      patientFirstName,
      patientLastName,
      email,
      patientDOB,
      memberId,
      groupNumber,
      appointmentDate,
      insurancename,
      phoneNumber,
      policyHolderRelationship,
    } = patientDetails;
      if (!patientFirstName || !patientLastName || !patientDOB) {
      setFieldsErr(true);
      return;
    }

    const requiredFieldsProvided =
      patientId && patientFirstName && patientLastName && patientDOB;
    const optionalFieldsProvided =
      memberId ||
      email ||
      phoneNumber ||
      groupNumber ||
      appointmentDate ||
      insurancename ||
      policyHolderRelationship;
    const formatPatientDOB = formatDate(patientDOB);
    const formatAppointmentDate = formatDate(new Date(appointmentDate));

    let payload = {};

    if (requiredFieldsProvided) {
      payload = {
        patientId,
        patientFirstName,
        patientLastName,
        patientDOB: formatPatientDOB,
        requestNpi: getLocalData("user")?.doctorNpis[0]?.toString() ?? "",
      };

      if (optionalFieldsProvided) {
        payload = {
          ...payload,
          memberId,
          groupNumber,
          appointmentDate: formatAppointmentDate,
          insurancename,
          phoneNumber,
          policyHolderRelationship,
          email,
          phoneNumber,
        };
      }
    }
    setIsLoading(true);

    try {
      const response = await post_Patient(payload);
      if (response && response?.data) {
        toast.success(response?.data?.message);
        setIsLoading(false);
        setPatientDetails({ payload });
        navigate(`../patients/${id}`);
        setPatientDetails(payload);
        setFieldsErr(false);
        setEditingEnabled(false);
      } else {
        toast.success(response?.data?.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const tabs = [
    {
      label: tabName,
    },
  ];

  return (
    <section className="EditPatient">
      <Box className={editSection}>
        <ViewPatientTab tabs={tabs} />
        <div className={mainDiv}>
          <div className={editPatient}>
            {renderEditIcon()}
          </div>
          <Box className={formWrapper}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={6}>
                  <FormControl
                    className={patientFirstNameField}
                    fullWidth
                  >
                    <TextField
                      label="First Name"
                      id="outlined-start-adornment"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline ": {
                          borderRadius: "12px",
                        },
                      }}
                      inputProps={{
                        maxLength: 20,
                        minLength: 2,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      onChange={handleChange}
                      value={patientDetails?.patientFirstName}
                      name="patientFirstName"
                      color="success"
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <FormControl className={formMargin} fullWidth>
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                      inputProps={{
                        maxLength: 20,
                        minLength: 2,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline ": {
                          borderRadius: "12px",
                        },
                      }}
                      onChange={handleChange}
                      value={patientDetails?.patientLastName}
                      name="patientLastName"
                      label="Last Name"
                      variant="outlined"
                      color="success"
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <FormControl className={formMargin} fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline ": {
                            borderRadius: "12px",
                            borderColor:
                              !patientDetails?.patientDOB && fieldsErr && "red",
                          },
                        }}
                        label="Date of Birth"
                        onChange={(val) =>
                          setPatientDetails({
                            ...patientDetails,
                            patientDOB: val,
                          })
                        }
                        name="{patientDOB}"
                        value={
                          patientDetails?.patientDOB
                            ? dayjs(patientDetails.patientDOB)
                            : null
                        }
                        variant="outlined"
                        color="success"
                        disabled
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <FormControl className={formMargin} fullWidth>
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline ": {
                          borderRadius: "12px",
                        },
                      }}
                      onChange={handleChange}
                      value={patientDetails?.phoneNumber}
                      name="phoneNumber"
                      label="Phone Number"
                      variant="outlined"
                      type="tel"
                      color="success"                
                      disabled={!editingEnabled}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <FormControl className={formMargin} fullWidth>
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline ": {
                          borderRadius: "12px",
                        },
                      }}
                      onChange={handleChange}
                      value={patientDetails?.email}
                      name="email"
                      label="Email"
                      type="email"
                      variant="outlined"
                      color="success"                     
                      disabled={!editingEnabled}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <FormControl className={formMargin} fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline ": {
                            borderRadius: "12px",
                          },
                        }}
                        label="Appointment Date"
                        onChange={(val) =>
                          setPatientDetails({
                            ...patientDetails,
                            appointmentDate: val,
                          })
                        }
                        name={"appointmentDate"}
                        value={
                          patientDetails?.appointmentDate
                            ? dayjs(patientDetails.appointmentDate)
                            : null
                        }
                        variant="outlined"
                        color="success"
                        disabled={!editingEnabled}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={
                              fieldsErr && !patientDetails?.appointmentDate
                            }
                            helperText={
                              fieldsErr && !patientDetails?.appointmentDate
                                ? "This field is required"
                                : "Required*"
                            }
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <FormControl className={formMargin} fullWidth>
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                      inputProps={{
                        maxLength: 20,
                        minLength: 2,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline ": {
                          borderRadius: "12px",
                        },
                      }}
                      onChange={handleChange}
                      value={patientDetails?.insurancename}
                      name="insurancename"
                      label="Insurance"
                      variant="outlined"
                      color="success"                      
                      disabled={!editingEnabled}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <FormControl className={formMargin} fullWidth>
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                      inputProps={{
                        maxLength: 30,
                        minLength: 2,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline ": {
                          borderRadius: "12px",
                        },
                      }}
                      onChange={handleChange}
                      value={patientDetails?.memberId}
                      name="memberId"
                      label="Member ID"
                      variant="outlined"
                      color="success"
                      type="text"
                      disabled={!editingEnabled}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <FormControl className={formMargin} fullWidth>
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                      inputProps={{
                        maxLength: 30,
                        minLength: 2,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline ": {
                          borderRadius: "12px",
                        },
                      }}
                      type="text"
                      onChange={handleChange}
                      value={patientDetails?.groupNumber}
                      name="groupNumber"
                      label="Group Number"
                      variant="outlined"
                      color="success"
                      disabled={!editingEnabled}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={12} hidden={!editingEnabled}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "2em",
                    }}
                  >
                    <ActionButton className={`${buttonStyles?.cancelButton} ${isLoading ? buttonStyles?.notAllowedCursor : buttonStyles?.pointerCursor}`} label='Cancel' onChange={toggleEditing} size='large'></ActionButton>
                    <ActionButton className={`${buttonStyles?.saveButtonStyle} ${isLoading ? buttonStyles?.notAllowedCursor : buttonStyles?.pointerCursor}`} type='submit' label='Save' showLoading={isLoading} onChange={toggleEditing} size='small' variant='contained' disabled={isLoading}></ActionButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </div>
      </Box>
    </section>
  );
};

export default PatientDetail;
