export const settingNpiFields = (details) => {
    const npiDetailsFields = [
        { label: 'ID:', value: details?.id },
        { label: 'NPI:', value: details?.npi },
        { label: 'Provider First Name:', value: details?.providerFirstName },
        { label: 'Provider Organization Name:', value: details?.providerOrganizationName },
        { label: 'Gender:', value: details?.gender },
        { label: 'Certification Date:', value: details?.certificationDate },
        { label: 'Last Updated:', value: details?.lastUpdated },
        { label: 'Enumeration Date:', value: details?.enumerationDate },
        { label: 'NPI Type:', value: details?.npiType },
        { label: 'Status:', value: details?.status },
        { label: 'Sole Proprietor:', value: details?.soleProprietor }
    ];
    return npiDetailsFields;
}
export const settingItemFields = (items) => {
    const itemFields = [
        { label: 'Issuer:', value: items?.issuer },
        { label: 'Primay Taxonomy:', value: items?.primayTaxonomy },
        { label: 'Selected Taxonomy:', value: items?.selectedTaxonomy },
        { label: 'State:', value: items?.state }
    ];
    return itemFields;
}
export const settingPrimaryPracticeAddressFields = (primary) => {
    const primaryPracticeAddressFields = [
        { label: 'Street1:', value: primary?.street1 },
        { label: 'Street2:', value: primary?.street2 },
        { label: 'State:', value: primary?.state },
        { label: 'City:', value: primary?.city },
        { label: 'Phone:', value: primary?.phone },
        { label: 'Fax:', value: primary?.fax },
        { label: 'Zip:', value: primary?.zip }
    ];
    return primaryPracticeAddressFields;
}

export const settingMailingAddress = (address) => {

    const mailingAddressFields = [
        { label: 'Street1:', value: address?.street1 },
        { label: 'Street2:', value: address?.street2 },
        { label: 'State:', value: address?.state },
        { label: 'City:', value: address?.city },
        { label: 'Phone:', value: address?.phone },
        { label: 'Fax:', value: address?.fax },
        { label: 'Zip:', value: address?.zip }
    ];
    return mailingAddressFields;
}

export const settingTaxonomyDetails = (details) => {
   
    const taxonomyFields = details?.map((item, index) => {
        return { label: 'Lisence Number', value: item?.licenseNumber },
               { label: '', value: item?.primayTaxonomy }
    })
    return taxonomyFields;
}

export const settingSecondaryAddress = (address) => {
    const secondaryAddress = [
        { label: 'City', value: address?.city },
        { label: 'State', value: address?.state },
        { label: 'Street1', value: address?.street1 },
        { label: 'Street2', value: address?.street2 },
        { label: 'Fax', value: address?.fax },
        { label: 'Phone', value: address?.phone },
        { label: 'Zip', value: address?.zip }
    ];
    return secondaryAddress;
}