import React from 'react'
import ResponsiveHorizontalBox from '../../components/shared/ResponsiveHorizontalBox'
import { patientOverviewHeadings } from '../../utils/staticData'
import { Box, Typography } from '@mui/material'
import CircleWrapper from '../../components/shared/CircleWrapper'
import { MdOutlineCreateNewFolder, MdOutlineNewLabel } from 'react-icons/md'
import { colors } from '../../utils/colors'
import { GrDocumentMissing } from 'react-icons/gr'
import { styles } from './styles'

const ClaimDashboardCards = () => {
    return (
        <ResponsiveHorizontalBox>
            {patientOverviewHeadings?.map((elem, index) => {
                return (
                    <Box item xs={12} md={4}
                        width={'100%'}
                        key={index} paddingX={{ xs: 1, sm: 2 }}
                    >
                        <Box boxShadow={3} paddingY={2} paddingX={2} borderRadius={4}
                            marginY={2}
                        >
                            <CircleWrapper >
                                {index === 0 ? <MdOutlineCreateNewFolder fill={colors?.themeGreen} /> : index === 1 ? <GrDocumentMissing stroke={colors?.themeGreen} /> : <MdOutlineNewLabel fill={colors?.themeGreen} />}
                            </CircleWrapper>
                            <Typography variant="body1" sx={styles.cardText}>
                                {elem}
                            </Typography>
                            <Typography variant="body1" sx={styles.cardValue} color={index === 2 ? colors?.danger : colors?.themeGreen}>
                                800
                            </Typography>
                        </Box>
                    </Box>
                )
            })}
        </ResponsiveHorizontalBox>
    )
}

export default ClaimDashboardCards