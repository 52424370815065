import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Grid, Tabs } from '@mui/material';
import styles from '../../css/addpatient.module.css'
import { styled } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import MyTabs from '../../components/tabs/MyTabs'
import { useLocation, useNavigate, } from 'react-router-dom';
import Plandetail from './Plandetail';
import { get_GetInusuranceDiscoveryByPatientId } from '../../services/registration';
import { CgCalendarDates } from "react-icons/cg";
import { IoIosJournal } from "react-icons/io";
import { PiShareNetworkDuotone } from "react-icons/pi";
import { FaCcAmazonPay } from "react-icons/fa";
import { FaHandsHelping } from "react-icons/fa";
import { FaMinusCircle } from "react-icons/fa";
import { colors } from '../../utils/colors';
import { FaIdCard } from "react-icons/fa";
import DetailsHeader from '../../components/shared/DetailsHeader';
import { SiInstatus } from 'react-icons/si';

function Discoverydetails1() {
  const { formWrapper } = styles;
  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();
  const [searchdata, setsearchdata] = useState([]);

  // route handlers
  const rowDataString = new URLSearchParams(location.search).get('rowData');
  const rowData = rowDataString ? JSON.parse(decodeURIComponent(rowDataString)) : null;
  const navigate = useNavigate();

  // states using route handlers
  const [topRightButtons] = useState([
    { name: 'Add Patient', action: () => navigate('/patients?preSelectedTab=2') },
    { name: 'New Search', action: () => navigate('/discoveryTab?preSelectedTab=2') },
    { name: 'Back to Results', action: () => navigate('/discoveryTab?preSelectedTab=1') }
  ])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (rowData && rowData.ID) {
          let Patient_id = rowData.ID;
          const data = await get_GetInusuranceDiscoveryByPatientId(Patient_id);
          let planSummaryData = [];
          planSummaryData = data.insuranceDiscoveryDto.planCoverageSummary;
          const deductibleData = data?.insuranceDiscoveryDto?.hbpC_Deductible_OOP_Summary?.individualDeductibleRemainingInNet?.value || data?.insuranceDiscoveryDto?.hbpC_Deductible_OOP_Summary?.individualDeductibleRemainingInNet;
          setsearchdata({
            ...planSummaryData, deductibleData,
            memberId: data?.insuranceDiscoveryDto?.demographicInfo?.subscriber?.identification?.find(elem => elem?.type === 'Member ID')?.code
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleTabChange = (newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const tabs =
    [
      { label: "Plan Detail", value: <Plandetail tabname="detail" rowData={rowData} /> },
      { label: "Coverage Details", value: <Plandetail tabname="coverage" rowData={rowData} /> },
      { label: "DemographicInfo", value: <Plandetail tabname="DemographicInfo" rowData={rowData} /> },
      { label: "In-Network", value: <Plandetail tabname="Innetwork" rowData={rowData} />, changeTab: 3 },
      {
        label: "Out-of-Network", value: <Plandetail tabname="Outofnetwork" rowData={rowData} />, changeTab: 4,
      },
      {
        label: "Speciality", value: <Plandetail tabname="Speciality" rowData={rowData} />, changeTab: 5,
      },
    ]

  const topCardsItems = [
    { name: 'Status', value: rowData.Status || 'N/A', icon: <SiInstatus fill={colors?.themeGreen} /> },
    { name: 'Network', value: searchdata.isProviderInNetwork || 'N/A', icon: <PiShareNetworkDuotone fill={colors?.themeGreen} /> },
    { name: 'Payer', value: rowData.Insurance || 'N/A', icon: <FaCcAmazonPay fill={colors?.themeGreen} /> },
    { name: 'Member Id', value: searchdata.memberId || 'N/A', icon: <FaIdCard fill={colors?.themeGreen} /> },
    { name: 'Copay', value: rowData.Copay || 'N/A', icon: <FaHandsHelping fill={colors?.themeGreen} /> },
    { name: 'Deductible', value: searchdata.deductibleData || 'N/A', icon: <FaMinusCircle fill={colors?.themeGreen} /> },
    { name: 'Plan Begin Date', value: searchdata.effectiveDate || 'N/A', icon: <CgCalendarDates fill={colors?.themeGreen} /> },
    { name: 'Plan Type', value: searchdata.policyType || 'N/A', icon: <IoIosJournal fill={colors?.themeGreen} /> },
  ]

  return (
    <Box sx={{ width: { xs: '100%' } }}>
      <DetailsHeader topRightButtons={topRightButtons} name={rowData?.Patient} dob={rowData?.DOB} />
      <Box >
        <Box>

          <Box className={formWrapper}>
            <Grid container rowSpacing={3} columnSpacing={8}>

              {topCardsItems?.map((elem, index) => {
                return (
                  <Grid item xs={12} sm={6} lg={3} key={index} >
                    <Box sx={{ boxShadow: '0px 4px 8px #5E8A75', borderRadius: 2, bgcolor: 'white', height: 130, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2, cursor: 'pointer', }}>
                      {elem?.icon}
                      <Box sx={{ textAlign: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold', color: colors?.themeGreen }}>{elem?.name}</Typography>
                        <Typography>{elem?.value}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
            <Box sx={{ width: '100%', mt: 5 }}>
              <Tabs>
                <MyTabs tabs={tabs} onChange={handleTabChange} activeTab={tabIndex}
                />
              </Tabs>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default Discoverydetails1;
