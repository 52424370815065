import React, { useState } from "react";
import SelectTwo from "../../components/select/SelectTwo";
import {
  Box,
  FormControl,
  Input,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PaginationWrapper from "../../components/pagination/PaginationWrapper";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FilterListIcon from "@mui/icons-material/FilterList";
import GridComponent from "../../css/GridComponent";
import Claim from "./Claim";
import MyTabs from "../../components/tabs/MyTabs";
import ClaimSearchresultdata from "../Claims/ClaimSearchresultdata";
import  "../../css/ClaimSearchStyles.css";
const Claimresults = () => {
  const tabs = [
    {
      label: "Search",
      value: <ClaimSearchresultdata />,
      changeTab: 1,
      button: "Back",
    },
    {
      label: "Patient Eligibility ",
      value: <disabled />,
      button: "New Search",
      changeTab: 0,
    },
  ];

  return (
    <section className="claim">
      <Box className="customBox">
        <MyTabs tabs={tabs} />
      </Box>
    </section>
  );
};

export default Claimresults;
