import { AgGridReact } from 'ag-grid-react'
import React, { useEffect, useState } from 'react'
import { IconCellRenderer, IconCellRendererTwo, gridOptions } from './configuration/NpiConfiguration'





const NpiResultsTable = ({ data }) => {
  const [rowData, setRowData] = useState(null);


  const columnDefs = () => {
    return [
      { headerName: 'First Name', field: 'First Name', width: 150, filter: true },
      { headerName: 'Last Name', field: 'Last Name', width: 150, filter: true },
      { headerName: 'Organization Name', field: 'Organization Name', width: 150, filter: true },
      { headerName: 'NPI', field: 'NPI', width: 150, filter: true },
      { headerName: 'Gender', field: 'Gender', width: 150, filter: true },
      { headerName: 'Npi Type', field: 'Npi Type', filter: true },
      { headerName: 'SoleProprietor', field: 'Sole Proprietor', filter: true },
      { headerName: 'Status', field: 'Status', filter: true },
      { headerName: 'Certification Date', field: 'Certification Date', width: 150 },
      { headerName: 'Action', field: 'Action', width: 150, cellRenderer: IconCellRenderer },
      { headerName: 'Action', field: 'Action', width: 150, cellRenderer: IconCellRendererTwo },
    ];
  };

  useEffect(() => {
    const rowData = data?.map(({ npi, id, gender, certificationDate, npiType, soleProprietor, enumerationDate, status, providerOrganizationName, providerFirstName, providerLastName }) => ({
      'First Name': providerFirstName,
      'Last Name': providerLastName,
      "Organization Name": providerOrganizationName,
      NPI: npi || 'not found',
      Gender: gender || 'not found',
      "Certification Date": certificationDate || 'not found',
      "Npi Type": npiType || 'not found',
      "Sole Proprietor": soleProprietor || 'not found',
      EnumerationDate: enumerationDate || 'not found',
      Status: status || 'not found',
      Action: '',
      Action: '',
      id
    }))
    setRowData(rowData)
  }, []);


  return (
    <div className="ag-theme-quartz"
      style={{ height: 500 }}  >
      <AgGridReact
        columnDefs={columnDefs()}
        rowData={rowData}
        gridOptions={gridOptions}
      />
    </div>
  )
}

export default NpiResultsTable;

