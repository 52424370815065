import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Sidebar from '../components/Sidebar'
import Bar from '../components/Bar'
import SmSidebar from '../components/SmSidebar'
import UseWidth from '../custom-hooks/UseWidth.js'
import Header from '../components/Header.jsx'
import styles from './Layout.module.css'
import { useAuth0 } from '@auth0/auth0-react';
import { clearAllLocalData } from "../utils/localStorageFunctions.js";

const Layout = ({ children }) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const { width } = UseWidth()
    const open = () => setOpenDrawer(!openDrawer)
    const { containerXXL } = styles

    const { isAuthenticated, loginWithRedirect, getIdTokenClaims, isLoading, logout } = useAuth0();

    useEffect(() => {
        //chech session
        let timer;
        const resetTimer = () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                clearAllLocalData();
                loginWithRedirect();
            }, 15 * 60 * 1000); // 15 minutes in milliseconds
        };

        if (isAuthenticated) {
            resetTimer();
            window.addEventListener('mousemove', resetTimer);
            window.addEventListener('keypress', resetTimer);
        }

        return () => {
            clearTimeout(timer);
            window.removeEventListener('mousemove', resetTimer);
            window.removeEventListener('keypress', resetTimer);
        };
    }, [isLoading, isAuthenticated, logout, getIdTokenClaims, loginWithRedirect]);

    return (
        <Box>
            <div className={containerXXL}>
                <Header />
            </div>
            <Box sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center'
                }}
                    className={containerXXL}
                >
                    {children}
                </div>
            </Box>
            {
                width <= 768 && <SmSidebar openDrawer={openDrawer} onOpen={open} />
            }
        </Box>
    )
}

export default Layout
