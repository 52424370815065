import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PatientsOverviewChart from './PatientsOverviewChart'
import PatientOverviewCards from './PatientOverviewCards'
import DateFilterModal from './FilterModal'
import {formatDateYMD } from '../../utils/DateFunctions'
import { getPatientOverviewChartDetails } from '../../services/addPatient'
import toast from 'react-hot-toast'
import {  patientsOverviewGraphData } from '../../utils/staticData'
import {  patientTabData } from './dataFormatters'
import HeadingPlusFilter from '../../components/HeadingPlusFilter'
import { dataLabels, dataSets } from '../../utils/DataFormatters/PatientsOverviewGraphDataFormatters'

const PatientsOverview = () => {
    const [selectedFilter, setSelectedFilter] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [startDate, setStartDate]= useState(null)
    const [endDate, setEndDate] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [filter, setFilter] = useState(false)
    const [patientsOverviewData, setPatientsOverviewData] = useState({patientsOverviewGraphData})
    const [patientsCardData, setPatientsCardData] = useState([])

    const dateFilterAction = () => {
        setSelectedFilter(`${formatDateYMD(new Date(startDate))}/${formatDateYMD(new Date(endDate))}`)
        setFilter(!filter)
    }

    const getOverviewData = async() => {
        try{ setIsLoading(true)
         const data = await getPatientOverviewChartDetails(selectedFilter)
         if(data?.newPatientsAdded){
             const labels = dataLabels(data)
             const datasets = dataSets(data, labels)
             setPatientsOverviewData({ labels, datasets })
             setPatientsCardData(data)
             setShowModal(false)
 
         }
        }
        catch(e){
             console.log(e,'error fetching data')
             toast.error('error fetching data')
        }
         finally{
             setIsLoading(false)
         }
     }

     useEffect(()=>{
        getOverviewData()
     },[selectedFilter, filter])

    return (
        <Box>
            <HeadingPlusFilter onClick={()=>setShowModal(true)} heading={'Patients Overview'}/>

            <Box display={'flex'} justifyContent={'space-between'} alignItems={'stretch'}
                flexDirection={{ xs: 'column', md: 'row' }}
            >

                <PatientOverviewCards data={patientTabData(patientsCardData)} isLoading={isLoading} />
                <Box
                    width={{ xs: '100%', md: '50%' }}
                    padding={2}
                >
                    <Box
                        boxShadow={3}
                        height={'100%'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        display={'flex'}
                        borderRadius={4}
                        padding={2}
                        flexDirection={'column'}
                    >
                        <PatientsOverviewChart  isLoading={isLoading} 
                            patientsOverviewData={patientsOverviewData}
                        />
                    </Box>
                </Box>
            </Box >
            
       <DateFilterModal
       open={showModal}
       handleClose={()=>setShowModal(false)}
       startDate={startDate}
       endDate={endDate}
       setStartDate={setStartDate}
       setEndDate={setEndDate}
       action={dateFilterAction}
       isLoading={isLoading}
       />
        </Box>
    )
}
export default PatientsOverview