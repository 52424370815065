import axios from "axios";
import toast from "react-hot-toast";
import { getLocalData } from "../utils/localStorageFunctions";
import { DownloadCSV } from "../utils/reusableFunctions.js"
import {  formatDateYMD } from "../utils/DateFunctions";
const API_BASE_URL = process.env.REACT_APP_API_URL;
export const post_Patient = async (requestData) => {
    try {
        const res = await axios
            .post(API_BASE_URL + '/api/Patient/AddPatient', requestData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        return res;
    } catch (error) {
        toast?.error(error?.response?.data?.detail ?? 'Failed to add patient');
    }
};

export const sync_Patient = async (requestData) => {
    try {
        const res = await axios.post(
            `${API_BASE_URL}/api/PatientFinderInquiry/${requestData?.firstname}/${requestData?.lastname}/${requestData?.dateOfBirth}?responsibleSystem=${requestData?.search}`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
            }
        );
        return res;
    } catch (err) {
        toast?.error(err?.response?.data?.detail ?? 'Failed to get Patient');
    }
};

export const bulk_Patient = async (requestData) => {
    const url = `${API_BASE_URL}/api/Patient/AddBulkPatients?requestNpi=${getLocalData('user')?.doctorNpis?.[0]?.toString() ?? ''}`;
    try {
        const response = await axios.post(url, requestData);

        if (response && response?.data && response?.data?.message) {
            toast?.success(response?.data?.message);    
        }
        if (response?.data?.csvFileByteArray) {
            DownloadCSV(response?.data?.csvFileByteArray, `Downloaded_Response (${new Date().getUTCDate()}-${new Date().getUTCMonth() + 1}-${new Date().getUTCFullYear()})?.csv`);
        }
        return response?.data;
    } catch (error) {
        if (error?.response?.data?.csvFileByteArray) {
            DownloadCSV(error?.response?.data?.csvFileByteArray, `Downloaded_Response (${new Date().getUTCDate()}-${new Date().getUTCMonth() + 1}-${new Date().getUTCFullYear()})?.csv`);
        }
        toast?.error(error?.response?.data?.errors ?? 'Failed to upload CSV');
    }
};

export const get_GetAllPatientsDataAsync = async (npiId) => {
    const url = `${API_BASE_URL}/api/Patient/GetAllPatientsData?npiId=${npiId}`;
    try {
        const response = await axios.get(url);
        return response?.data;
    } catch (error) {
        console.log(error)
    }
};

export const get_GetPatientDataById = async (patientId) => {
    const url = `${API_BASE_URL}/api/Patient/GetPatientDataById/${patientId}`;
    try {
        const response = await axios.get(url);
        return response?.data;
    } catch (error) {
        console.log(error)
    }
};

export const get_ExportPatientsRecords = async (patientData) => {

    const url = `${API_BASE_URL}/api/Patient/ExportPatientsRecords`;
    try {
        const response = await axios.post(url, patientData, {
            headers: {
                "Content-Type": "application/json"
            },
        });
        return response?.data;
    } catch (error) {
        console.log(error)
    }

};

export const get_GenerateEmptyCSVAsync = async () => {
    const url = `${API_BASE_URL}/api/Patient/GenerateEmptyCsvTemplateAsync`;
    try {
        const response = await axios.get(url);
        return response?.data;
    } catch (error) {
        console.log(error)
    }
};

export const getPatientOverviewChartDetails = async (date) => {
    const dates = date || `${formatDateYMD(new Date())}/${formatDateYMD(new Date())}`
    const npiId = getLocalData("user")?.npiInfoList?.[0]?.id;
    const url = `${API_BASE_URL}/api/Patient/GetPatientCounts/${dates}?npiId=${npiId}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json"
        },
      });
      return response?.data?.patientGraphDetails;
    } catch (error) {
      console.log(error,'errorr');
      toast.error(error?.response?.data ?? 'Failed to get data')
    }
  }