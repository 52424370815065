import React from 'react'
import HorizontalBox from './shared/HorizontalBox'
import { Button, Typography } from '@mui/material'
import { styles } from './style'
import { sharedStyles } from '../sharedStyles'

const HeadingPlusFilter = ({onClick,heading}) => {
  return (
    <HorizontalBox sx={sharedStyles?.justifyBetween}>
    <Typography variant="h5">
        {heading}
    </Typography>
    <Button
    onClick={() => onClick ? onClick() : {}}
    sx={styles?.filterButton}> Filter</Button>
</HorizontalBox>
  )
}

export default HeadingPlusFilter