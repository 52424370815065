import React, { useState, useEffect } from 'react';
import SelectTwo from '../../components/select/SelectTwo';
import { Box, FormControl, InputAdornment, IconButton, Button, TextField, Autocomplete } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PaginationWrapper from '../../components/pagination/PaginationWrapper';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FilterListIcon from '@mui/icons-material/FilterList';
import GridComponentClaims from '../../css/GridComponentClaims';
import { get_GetAllPatientsDataAsync, post_Patient } from "../../services/addPatient";
import axios from 'axios';

const Claim = () => {
    const [locations, setLocations] = useState('All Locations');
    const pagename = "Claim";
    const [input, setInput] = useState("");
    const [suggestions, setSuggestions] = useState([]);

    const fetchData = async (value) => {
        try {
            let npiInfo = JSON.parse(localStorage.getItem('selectedNpi'));
            const patientdata = await get_GetAllPatientsDataAsync(npiInfo?.id);

            const flattenedDataObjList = patientdata.patientDataList.map(patient => ({
                firstName: patient.firstName,
                lastName: patient.lastName,
                dob: patient.dob
            }));

            const results = flattenedDataObjList.filter(patient => {
                return (
                    value &&
                    patient.firstName &&
                    patient.lastName &&
                    patient.dob &&
                    (patient.firstName.toLowerCase().includes(value.toLowerCase()) ||
                        patient.lastName.toLowerCase().includes(value.toLowerCase()) ||
                        patient.dob.toLowerCase().includes(value.toLowerCase()))
                );
            });
            setSuggestions(results);
            //setSuggestions(flattenedDataObjList);
        } catch (error) {
            console.error('Error fetching patient data:', error);
        }
    };

    const handleChange = (event, value) => {
        setInput(value);
        fetchData(value);
    };

    useEffect(() => {
        fetchData(input); // Fetch data when component mounts
    }, []); // Empty dependency array ensures this runs once on mount

    return (
        <Box>
            <Box>
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', gap: '1em' }}>
                    <FormControl fullWidth>
                        <Autocomplete
                            freeSolo
                            options={suggestions.map((option) => `${option.firstName} ${option.lastName} - ${option.dob}`)}
                            value={input}
                            onInputChange={handleChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Search Patients"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton aria-label="search">
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Box>
            </Box>
            <Box sx={{ marginTop: '2em' }}>
                <GridComponentClaims pagename={pagename} />
            </Box>
        </Box>
    );
};

export default Claim;
