import { Button } from "@mui/material";

export const payerGridOptions = {
  // Enable pagination
  pagination: true,
  // Set the number of rows per page
  paginationPageSize: 10, // Adjust as needed
  // Optional: Specify the layout of pagination components
  domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
  overlayNoRowsTemplate: `
  <span class="ag-overlay-loading-center">
      Loading
  </span>
`
  // Other grid options...
};


export const veiwPayer = ({ data }) => {
  return (
    <div className="flex">
      <Button variant='contained' color='success' size='small' sx={{ borderRadius: '8px', marginBottom: '0.5em' }}>
        View Details
      </Button>
    </div>
  )
}