import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import TableHeader from "../../components/TableHeader";
import GridComponentPatients from "../../components/table/GridComponentPatients";
import { get_ExportPatientsRecords, get_GetAllPatientsDataAsync } from "../../services/addPatient";
import toast from "react-hot-toast";
import styles from "../../css/patientManagement.module.css"
import { base64ToByteArray, isValidBase64 } from "../../utils/reusableFunctions";

const PatientManagement = () => {
  const [locations, setLocations] = useState("All Locations");
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState();
  const [filterPatient, setFilterPatient] = useState("");


  
  useEffect(() => {
    setIsLoading(true);
    let npiId = JSON.parse(localStorage.getItem('selectedNpi'));
    get_GetAllPatientsDataAsync(npiId?.id).then(res => {
      const data = res?.patientDataList;
      const reversedARR = data?.reverse();
      setData(reversedARR)
      setIsLoading(false)
    }).catch(err => {
      if (err?.response?.status === 400) {
        alert('Failed to fetch');
        setIsLoading(false)
      } else if (err?.response?.status === 500) {
        alert('Something went wrong')
        setIsLoading(false)
      }
    })
  }, [])

  const handlePatientExport = async (data) => {
    try {
      const payload = data && data?.length > 0 ? data?.map(patientData => ({
        id: patientData?.id,
        patientName: patientData?.firstName + ' ' + patientData?.lastName,
        appointmentDate: patientData?.appointments[0],
        status: patientData?.patientInsuranceDetails?.map(insuranceDetail => insuranceDetail).join(', '),
        insuranceName: patientData?.patientInsuranceDetails?.map(insuranceDetail => insuranceDetail).join(', '),
        phone: patientData?.phone,
        deductible: '',
        copay: ''
      })) : [];

      const res = await get_ExportPatientsRecords(payload);   
      const base64String = res?.csvFileByteArray;

      if (!isValidBase64(base64String)) {
        toast?.error('Invalid base64 string received')
      }
    
      const byteArray = base64ToByteArray(base64String);
      const blob = new Blob([byteArray], { type: 'text/csv' });
      const curl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = curl;
      a.download = `Export_Patient_Data (${new Date().getUTCDate()}-${new Date().getUTCMonth() + 1}-${new Date().getUTCFullYear()}).csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      
    } catch (error) {
      const base64String = error?.response?.data?.csvFileByteArray;

      if (!isValidBase64(base64String)) {
        toast?.error('Invalid base64 string received')
      }

      const byteArray = base64ToByteArray(base64String);
      const blob = new Blob([byteArray], { type: 'text/csv' });
      const curl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = curl;
      a.download = `Export_Patient_Error_Response (${new Date().getUTCDate()}-${new Date().getUTCMonth() + 1}-${new Date().getUTCFullYear()}).csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      toast?.error(error?.response?.data?.errors ?? 'Failed to upload CSV');
        console.log('Error while export file', error);
      }
  }

  return (
    <Box>
      <TableHeader locations={locations} setLocations={setLocations} setFilterPatient={setFilterPatient} />
      <Box className={styles?.mainBox}>
        <GridComponentPatients pagename={"Patient"} data={data} isLoading={isLoading} filterPatient={filterPatient} />
      </Box>
      <Box className={styles?.rowDataBox}>
        <Button
          disableUnderline={false}
          size="small"
          color="success"
          startIcon={<CloudDownloadIcon />}
          variant="contained"
          onClick={() => handlePatientExport(data)}
        >
          Export
        </Button>
      </Box>
    </Box>
  );
};

export default PatientManagement;
