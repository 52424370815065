import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { editEventModalStyles } from './styles';

const EditEventModal = ({ open, onClose, onEditEvent, event }) => {
  const [title, setTitle] = useState('');
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [patientName, setPatientName] = useState('');
  const [notes, setNotes] = useState('');

  useEffect(() => {
    if (event) {
      setTitle(event?.title?.split(' - ')?.[0] || '');
      setStart(event?.start ? new Date(event.start) : new Date());
      setEnd(event?.end ? new Date(event.end) : new Date());
      setPatientName(event?.patientName || '');
      setNotes(event?.notes || '');
    }
  }, [event]);

  const handleEditEvent = () => {
    onEditEvent?.({
      ...event,
      title,
      start,
      end,
      patientName,
      notes
    });
    onClose?.();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={editEventModalStyles.modalBox}>
        <Box sx={editEventModalStyles.headerBox}>
          <Typography variant="h6" component="h2">
            Edit Appointment
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <TextField
          label="Appointment Title"
          value={title}
          onChange={(e) => setTitle(e.target?.value)}
          sx={editEventModalStyles.textField}
        />
        <TextField
          label="Patient Name"
          value={patientName}
          onChange={(e) => setPatientName(e.target?.value)}
          sx={editEventModalStyles.textField}
        />
        <TextField
          label="Start"
          type="datetime-local"
          value={moment(start).format('YYYY-MM-DDTHH:mm')}
          onChange={(e) => setStart(new Date(e.target?.value))}
          sx={editEventModalStyles.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="End"
          type="datetime-local"
          value={moment(end).format('YYYY-MM-DDTHH:mm')}
          onChange={(e) => setEnd(new Date(e.target?.value))}
          sx={editEventModalStyles.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="Notes"
          value={notes}
          onChange={(e) => setNotes(e.target?.value)}
          sx={editEventModalStyles.notesField}
          multiline
          rows={3}
        />
        <Button 
          variant="contained" 
          onClick={handleEditEvent}
          sx={editEventModalStyles.updateButton}
        >
          Update Appointment
        </Button>
      </Box>
    </Modal>
  );
};

export default EditEventModal;
