import React, { useState } from "react";
import { Link, NavLink, useLocation, useSearchParams } from "react-router-dom";
import DropdownHeader from "./dropdown/DropdownHeader";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import UseWidth from "../custom-hooks/UseWidth";
import { IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth0 } from '@auth0/auth0-react';
import { clearAllLocalData } from "../utils/localStorageFunctions.js";
import { claimPaths, discoveryPaths } from "../utils/staticData.js";

function TemporaryDrawer() {
  const [open, setOpen] = React.useState(false);
  const [routes] = useState([{ key: 'Home', to: '/home' }, { key: 'Patients', to: '/patients' }, { key: 'Discovery', to: 'Discoverytab' }, { key: 'Eligibilty', to: 'eligibility' }, { key: 'Claims', to: 'ClaimTab' },
    // { key: 'Payments', to: 'payments' }
  ])
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const verifiedUserNpi = JSON.parse(localStorage?.getItem('user'))?.doctorNpis[0] ?? null;
  const DrawerList = (
    <Box sx={{ width: 250 }} role="navigation" onClick={toggleDrawer(false)}>
      <List>
        {routes.map((text) => (
          <ListItem key={text.key} disablePadding>
            <ListItemButton component={NavLink} to={text.to}
              onClick={(e) => {
                (!verifiedUserNpi) && e?.preventDefault()
              }}
            >
              <ListItemText>
                <ListItemText primary={text.key} />
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <IconButton onClick={toggleDrawer(true)} sx={{ borderRadius: '50%', background: '#5e8a75', ":hover": { background: '#5e8a75', transform: 'scale(1.1)', transition: '0.2s linear all' }, transition: '0.2s linear all' }}>
        <MenuIcon sx={{ color: '#fff' }} />
      </IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}

const Header = () => {
  const { pathname } = useLocation();
  const { width } = UseWidth()
  const { loginWithRedirect, } = useAuth0();
  const [searchParams] = useSearchParams();
  const throughSearchParam = searchParams.get('through');

  const handleOptionSelect = () => {
    clearAllLocalData();
    loginWithRedirect();
  };

  const verifiedUserNpi = JSON.parse(localStorage?.getItem('user'))?.doctorNpis[0] ?? null;

  return (
    <section className="header-new">
      <div className="left-col" style={{ display: 'flex', alignItems: 'center' }}>
        <Link>
          <img src="/assets/appzLogo.png" width={200} className="logo" alt="appzhealth" />
        </Link>
      </div>

      <div className="right-col">
        <nav>
          <ul>
            <li>
              <NavLink
                className={pathname === "/home" ? "active" : ""}
                to={"/home"}
                onClick={(e) => {
                  if (!verifiedUserNpi) {
                    e.preventDefault();
                  }
                }}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                className={pathname === "/patients" ? "active" : ""}
                to={"/patients"}
                onClick={(e) => {
                  if (!verifiedUserNpi) {
                    e.preventDefault();
                  }
                }}
              >
                Patients
              </NavLink>
            </li>
            <li>
              <NavLink
                className={
                  discoveryPaths?.includes(pathname) || throughSearchParam === 'Discovery' ? "active" : ""}
                to={"Discoverytab"}
                onClick={(e) => {
                  if (!verifiedUserNpi) {
                    e.preventDefault();
                  }
                }}
              >
                Discovery
              </NavLink>
            </li>
            <li>
              <NavLink
                className={pathname === "/eligibility" ? "active" : ""}
                to={"eligibility"}
                onClick={(e) => {
                  if (!verifiedUserNpi) {
                    e.preventDefault();
                  }
                }}
              >
                Eligibility
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"ClaimTab"}
                className={claimPaths?.includes(pathname) && 'active'}
                onClick={(e) => {
                  if (!verifiedUserNpi) {
                    e.preventDefault();
                  }
                }}
              >
                Claims
              </NavLink>
            </li>
            <li>

              {/* <NavLink
                to={"payments"}
                className={pathname === "/payments" ? "active" : ""}
                onClick={(e) => {
                  if (!verifiedUserNpi) {
                    e.preventDefault();
                  }
                }}
              >
                Payments
              </NavLink> */}
            </li>
          </ul>
        </nav>
        <div
          className="header-right"
        >
          <DropdownHeader handleOptionSelect={handleOptionSelect} />
          {
            width <= 1116 ?
              <TemporaryDrawer />
              : ''
          }
        </div>
      </div>
    </section>
  );
};

export default Header;
