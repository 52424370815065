import * as React from 'react';
import { Box, Button } from '@mui/material';
import styles from './MyTabs.module.css';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ViewPatientTab(props) {
  const { myTabs, tabInner } = styles;
  const navigate  = useNavigate();

  function handleBackButtonClick() {
    navigate('../patients?preSelectedTab=1');
  };

  return (
    <Box sx={{ width: '100%'}}>
      <Box className={myTabs} sx={{ borderColor: 'divider', borderRadius:'12px', marginLeft:0 }}>
        <Tabs className={tabInner} variant='scrollable' scrollButtons={true} allowScrollButtonsMobile aria-label="basic tabs example">
          { props?.tabs?.length &&
            props?.tabs?.map((tab, index) => (
              <Tab key={index} label={tab?.label} {...a11yProps(index)}
                className={styles?.viewTab}
              />
            ))
          }
        </Tabs>
        <Button
          variant='contained'
          size='small'
          color='success'
          onClick={handleBackButtonClick}
          className={styles?.tabButton}
          sx={{
              marginY: '0.5em',
              marginX: '38px',
              paddingX: '2em',
          }}>
          Back
        </Button>
      </Box>
    </Box>
  );
}