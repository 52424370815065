import { colors } from "../colors"
import { noDataInGraphStr } from "../staticData"
import toast from "react-hot-toast"

   // setting labels for chart
   export const dataLabels = (data) => {
    if(data?.newPatientsAdded?.length){
        const labels = data?.newPatientsAdded?.map(elem => elem?.month)
        return handleSingleElem(labels)
    }
    else if(data?.patientsHasInsurance?.length){
        const labels = data?.patientsHasInsurance?.map(elem => elem?.month)
        return handleSingleElem(labels)
    }
    else if(data?.patientsWithoutInsurance?.length){
        const labels = data?.patientsWithoutInsurance?.map(elem => elem?.month)
        return handleSingleElem(labels)
    }
    else {
        toast.error(noDataInGraphStr)
        return []}
}

// setting datasets for chart
export const dataSets = (data,labels) => {
    const dataSet = [
        {
        label: 'New Patients Added',
        data: labels?.length ? handleSingleElem(data?.newPatientsAdded?.map(elem => elem?.count)) : [],
        backgroundColor: colors?.lightGreen,
        borderColor: colors?.themeGreen,
        borderWidth: 1
        },
               {
        label: 'Patients having insurance',
        data: labels?.length ? handleSingleElem(data?.patientsHasInsurance?.map(elem => elem?.count)): [],
        backgroundColor: colors?.lightYellow,
        borderColor: colors?.yellow,
        borderWidth: 1
      },
      {
        label: 'Patients missing insurance',
        data: labels?.length ? handleSingleElem(data?.patientsWithoutInsurance?.map(elem => elem?.count)):[],
        backgroundColor: colors?.lightRed,
        borderColor: colors?.danger,
        borderWidth: 1
      }
    ]
    return dataSet
}

 // handling data if there's only one item in array
 const handleSingleElem = (array) => {
    return array?.length === 1 ? [array[0]].concat(array) : array
}


export const getCountFromArray = (arr) => {
    const count = arr?.reduce((prev, curr)=>{
        return prev + curr?.count
    },0);
    return count
}