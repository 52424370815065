import React, { useState } from 'react';
import { Button, IconButton, Menu, MenuItem, Paper, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import { getLocalData } from '../../utils/localStorageFunctions';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import { styles } from './styles';


const DropdownHeader = ({ handleOptionSelect }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const user = getLocalData("user")?.userName;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        // Handle logic for option 1
        handleOptionSelect();
        handleClose();
    };

    const handleOption2 = () => {
        // Handle logic for option 2
        handleClose();
    };

    const onNpiChange = (npiInfo) => {
        //Handle logc for NPI Selection
        localStorage.setItem('selectedNpi', JSON.stringify(npiInfo));
    };

    let npiInfoList = JSON.parse(localStorage.getItem('user'))?.npiInfoList;
    let selectedNpiInfo = JSON.parse(localStorage.getItem('selectedNpi'));

    return (
        <div>
            <Button onClick={handleClick} sx={styles?.dropdownBtn} className='dropdown-header' size='small' variant="text" >
                {user ? user : <Skeleton variant="text" sx={styles?.skeleton} />}
                <KeyboardArrowDownIcon />
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={styles?.menu}
                transformOrigin={{
                    horizontal: 'left'
                }}
            >
                <MenuItem component={Link} to={'/userSettings'} sx={styles?.menuItem} onClick={handleOption2}>
                    <AccountCircleIcon sx={styles?.menuItemIcon} />
                    User Settings
                </MenuItem>
                {npiInfoList && npiInfoList.length > 0 && npiInfoList.map((x) => (
                    <MenuItem key={x.id} sx={styles?.menuItem} onClick={() => onNpiChange(x)} selected={selectedNpiInfo && x.id == selectedNpiInfo.id}>
                        <MedicalServicesIcon sx={styles?.menuItemIcon} />
                        <Link state={{ tab: 1 }} to={'/userSettings'} style={styles?.link}>
                            {x.name[0].toUpperCase() + x.name.slice(1).toLowerCase()}
                        </Link>
                    </MenuItem>
                ))}
                <MenuItem sx={styles?.menuItem}
                    onClick={logout}
                >
                    <LogoutIcon
                        sx={styles?.logoutIcon}
                    />
                    Logout
                </MenuItem>
            </Menu>
        </div>
    );
};



export default DropdownHeader;
