import { AgGridReact } from 'ag-grid-react'
import React, { useEffect, useState } from 'react'
import { addPayer, payerGridOptions, veiwPayer } from './configuration/PayerTableConfiguration';


const PayersDetail = ({ data, filterPayer }) => {
    const [rowData, setRowData] = useState(null)

    const columnDefs = () => {
        return [
            { headerName: 'Payer Code', field: 'Payer Code', width: 250, filter: true },
            { headerName: 'Payer Name', field: 'Payer Name', width: 250, filter: true },
            { headerName: 'Type', field: 'Type', width: 250, filter: true },
            { headerName: 'Claim Status', field: 'Claim Status', width: 250, filter: true },
            { headerName: 'Column 1', field: 'Column 1', width: 250, filter: true },
            { headerName: 'Eligibility', field: 'Eligibility', width: 250, filter: true },
            { headerName: 'Action', field: 'Action', width: 250, cellRenderer: veiwPayer },
        ];
    };

    useEffect(() => {
        if (data) {
            const filterPayerName = filterPayer?.slice(0, filterPayer?.indexOf(" - "))?.toLowerCase();
            const filterPayerCode = filterPayer?.slice(filterPayer?.indexOf(" - ") + 3)?.toLowerCase();
      
         const filteredData = data.filter(payer =>
                payer?.payerCode?.toLowerCase()?.includes(filterPayerCode.toLowerCase() || '') ||
                payer?.payerName?.toLowerCase()?.includes(filterPayerName.toLowerCase() || '')
            );

            const rowData = filteredData?.length && filteredData?.map(({ payerCode, type, eligibility, claimStatus, column1, payerName }) => ({
                'Payer Code': payerCode,
                'Payer Name': payerName,
                'Type': type,
                Eligibility: eligibility || 'not found',
                "Claim Status": claimStatus || 'not found',
                "Column 1": column1 || 'not found',
                Action: ''
            }))
            setRowData(rowData)
        }

    }, [data, filterPayer])

    return (
        <div className="ag-theme-quartz"
            style={{ height: 500 }}  >
            <AgGridReact
                columnDefs={columnDefs()}
                rowData={rowData}
                gridOptions={payerGridOptions}
            />
        </div>
    )
}

export default PayersDetail;
